import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import packageInfo  from '../../package.json';

const AppHeader = (props) => {
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const searchInputRef = React.createRef();
  const currentLocation = useLocation();
  const hideButtonForPaths = ['/order', "/profile", '/items/'];
  const shouldHideButton = hideButtonForPaths.some(segment => currentLocation.pathname.includes(segment))
  const [hideMenuTitles, setHideMenuTitles] = useState(false);
  const appVersion = packageInfo.version;
  const directToVenue = !window.location.href.includes('venuesa');
  useEffect(() => {
    const MenuTitles = document.querySelectorAll(".menu-group-title");
   
    if (hideMenuTitles) {
        MenuTitles.forEach(block => {
            block.classList.add("d-none");
        });

    } else {
        // If hideMenuTitles is false, simply show all titles
        MenuTitles.forEach(block => {
            block.classList.remove("d-none");
        });
    }
}, [hideMenuTitles]);


  // search functionality
  const [searchTerm, setSearchTerm] = useState("");
  const checkStickiness = () => {
    const vh = window.innerHeight;
    
    const threshold = (vh * 10) / 100;
    setIsSticky(window.pageYOffset > threshold);
  };

  const handleSearchClick = () => {
    setIsSearchVisible(!isSearchVisible);
  
    if (!isSearchVisible) {
      setTimeout(() => {
        const searchInput = document.querySelector(".search-input");
        searchInput?.focus();
      }, 500);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
      setIsSearchVisible(true);
      searchInputRef.current?.blur();
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearchInputClick = (e) => {
    e.stopPropagation();
  };



  useEffect(() => {
    window.addEventListener("scroll", checkStickiness);

    return () => {
      window.removeEventListener("scroll", checkStickiness);
    };
  }, []);



  useEffect(() => {
    const handleScroll = () => {
      const vh = window.innerHeight;
      const threshold = (vh * 10) / 100; 
      setScroll(window.scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    
    // Get all content-block elements
    const contentBlocks = document.querySelectorAll('.content-block');

    contentBlocks.forEach(block => {
        const blockContent = block.textContent.toLowerCase();

        if (value === "" || blockContent.includes(value)) {
            block.classList.remove('d-none');
             
        } else {
            block.classList.add('d-none');
        }
        if(value===""){
          setHideMenuTitles(false);
        }
    });

    // Adjust visibility of menu titles
    const containerBlocks = document.querySelectorAll('.container-inline');
    containerBlocks.forEach(container => {
        const visibleItems = container.querySelectorAll('.vendor-item:not(.d-none)');
        const menuTitle = container.previousElementSibling;
        if (menuTitle && menuTitle.classList.contains('menu-group-title')) {
            if (visibleItems.length > 0) {
                menuTitle.classList.remove('d-none');
            } else {
              setHideMenuTitles(false);
                menuTitle.classList.add('d-none');
            }
        }
    });
};



const handleOutsideClick = () => {
  setSearchTerm("");
  setIsSearchVisible(false);

  // Ensure all content-blocks are visible
  const contentBlocks = document.querySelectorAll('.content-block');
  contentBlocks.forEach(block => block.classList.remove('d-none'));

  // Ensure all menu titles are visible
  const MenuTitles = document.querySelectorAll(".menu-group-title");
  MenuTitles.forEach(title => title.classList.remove('d-none'));
};
  return (
    <div
      className={`appHeader   p-1 ${
        scroll ? "header-toggle " : "bg-none"
      }`}
    >
    <div className="left ">
    {directToVenue?<div
          className= {scroll ? "d-block btn-back " : "btn-back-top "} 
          style={{ width: "40px", height: "40px" }}
          onClick={() => navigate(-1)}
        >
          <span className="back">
          <ion-icon
            name="chevron-back-outline"
            style={{ fontSize: "40px" }}
          ></ion-icon>
          </span>
        </div>:null}
        </div>
      <div className={` header-name  ${scroll ? "" : "d-none"}`}>
       
          <h3 className="text-dark">{props.name}</h3>
       
      </div>
      <div className={`right`}>
      <div
        className={`${
          shouldHideButton ? 'd-none' : 
          isSearchVisible
            ? "search-out button-search   d-flex flex-row-reverse align-items-center"
            : !isSticky
            ? "button-search-top"
            : "button-search "
        }`}
        style={{ width: "40px", height: "40px" }}
        onClick={handleSearchClick}
      >
        

        <div
          className="rounded-circle d-flex justify-content-center align-items-center"
          style={{ width: "40px", height: "40px" }}
          onClick={isSearchVisible ? handleOutsideClick : null}
        >
          
          <ion-icon
            name={isSearchVisible ? "close-outline" : "search-outline"}
            style={{ fontSize: "30px" }}
          ></ion-icon>
        </div>
        <p className="app-version">V{appVersion}</p>
        {isSearchVisible && (
          
          <input
            type="text"
            className={`search-input  p-1 border-0 mx-1 ${
              isSearchVisible ? "visible" : ""
            }`}
            placeholder={`Search`}
            value={searchTerm}
            onChange={handleSearchChange}
            onClick={handleSearchInputClick}
            onKeyDown={handleKeyDown}
            ref={searchInputRef}
          />
        )}
      </div>

      </div>
    </div>
  );
};

export default AppHeader;