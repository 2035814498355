import React, { Fragment, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BottomMenu from "../common/BottomMenu";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import NotificationsBar from "../common/NotificationsBar";
import AppHeader from "../common/AppHeader";
import api from "../services/api.service";
import Loader from "../common/Loader";
import VariantsOptionsUpdates from "../components/VariantsOptionsUpdates";

const ItemDetailsPage = () => {
  const { venueId, vendorId, itemId } = useParams();
  const [item, setItem] = useState({
    name: "Item",
  });
  const [itemStatus, setItemStatus] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isUpdating, updateStatus } = VariantsOptionsUpdates();
  const [optionGroups, setOptionGroups] = useState([]);
  const [variants, setVariants] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState(new Map());

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/items/" + itemId)
      .then((response) => {

        let data = response.data.data;
        setItem(data || []);
        setVariants(data.variants.data || []);
        setItemStatus(data || []);
        var optionGroups = { data: [] };
        if (data.option_groups && data.option_groups.data) {
          optionGroups.data = optionGroups.data.concat(data.option_groups.data);

        }

        if (
          data.group &&
          data.group.option_groups &&
          data.group.option_groups.data
        ) {
          optionGroups.data = optionGroups.data.concat(
            data.group.option_groups.data
          );
        }


        setOptionGroups(optionGroups);




        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);

        console.error(error);
      });
  }, [itemId, navigate, venueId, vendorId]);
  // set button stock status

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleItemStatus = () => {
    const newStatus = itemStatus.status === 'active' ? false : true;
    updateStatus('item', itemId, newStatus).then(() => {
      // After the API call is successful, update the local state
      setItemStatus(prevStatus => ({
        ...prevStatus,
        status: newStatus ? 'active' : 'inactive'
      }));
    });
  }



  const handlecheckBoxChange = (e, optionId, type) => {
    const isChecked = e.target.checked;
    let status = isChecked ? true : false;
    console.log("status", status)
    updateStatus(type, optionId, status)
      .then(result => {
        setCheckedStatus(prevStatus => new Map(prevStatus).set(optionId, status));
      })
      .catch(error => {
        console.error("Error updating status:", error);
        setCheckedStatus(prevStatus => new Map(prevStatus).set(optionId, status));
      });

  };


  return (
    <Fragment>
      <AppHeader name={item.name}></AppHeader>

      {isLoading ? (
        <div id="appCapsule">
          <Loader />
        </div>
      ) : (
        <div id="appCapsule ">
          {windowWidth < 800 ? (
            <div className="d-flex  justify-content-center align-items-center flex-column ">
              <div className=" col-sm-12 ItemDetail">
                <div className="text-center"></div>

                <div className="card m-1 ">
                  <h1 className="card-title pt-3 px-5 mx-2 text-center">
                    {item.name}
                  </h1>
                  {item.image_url && (
                    <div className="p-1 position-relative text-center">
                      <img
                        src={item.image_url}
                        className="rounded shadow product-img"
                        alt="product "
                      />
                    </div>
                  )}
                  <div className="card-body">
                    <p className="card-text">{item.description}</p>
                    <hr />
                    <h3>
                      Select the items you'd like to set as 'out of stock'. If the
                      entire item is unavailable, tap the button at the bottom to
                      mark it as such.
                    </h3>

                    <b>Mark the main product as 'out of stock' if you have no variants or options to fulfill orders</b>
                  </div>
                </div>

                <div className="section full  m-1">
                  <div className="card">
                    <div className="card-body">
                      {item.variants && item.variants.data.length > 1 ?
                        <div>
                          <h5 className="card-title">Sizes / Variations</h5>
                          <div className="pb-3 fs-5">
                            Mark sizes or variations out of stock
                          </div>
                        </div>
                        : null}
                      {item.variants &&
                        item.variants.data &&
                        item.variants.data.length > 1
                        ? variants.map((variant) => {

                          return (
                            <div
                              className="form-check form-switch mb-1 vendor-switch"
                              key={`variant-${variant.id}`}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input variants"
                                name="variant_id"
                                id={`variant-${variant.id}`}
                                data-id={variant.id}
                                disabled={isUpdating}
                                onChange={(e) => handlecheckBoxChange(e, variant.id, "variant")}

                                checked={checkedStatus.has(variant.id) ? checkedStatus.get(variant.id) : variant.status === "active"}

                              />
                              <label
                                className="form-check-label"
                                htmlFor={`variant-${variant.id}`}
                              >
                                <b className="fs-4 pb-1 ">{variant.name}</b>
                                <br />
                              </label>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>
                </div>

                {optionGroups &&
                  optionGroups.data &&
                  optionGroups.data.length >= 1 ? (
                  <div className="section full m-1 ">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Options / Extras</h5>
                        <div className="fs-5">
                          Mark options or extras out of stock
                        </div>
                        {optionGroups.data.map((optionGroup) => {

                          return (
                            <Fragment key={`option-group-${optionGroup.id}`}>
                              <h3 className="card-title fs-4 pt-2">
                                {optionGroup.name}
                              </h3>
                              {optionGroup.options.data.map((option) => {
                                return (
                                  <div
                                    className="form-check form-switch mb-1 vendor-switch"
                                    key={`option-${option.id}`}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input options"
                                      name="option_id"
                                      id={`option-${option.id}`}
                                      data-id={option.id}
                                      disabled={isUpdating}
                                      onChange={(e) => handlecheckBoxChange(e, option.id, "option")}

                                      checked={checkedStatus.has(option.id) ? checkedStatus.get(option.id) : option.status === "active"}

                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`option-${option.id}`}
                                    >
                                      <b className="fs-4 pb-1 ">
                                        {option.name}
                                      </b>
                                      <br />
                                    </label>
                                  </div>
                                );
                              })}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                    <hr />
                  </div>
                ) : null}

                <div className="section full">
                  <div className=" pt-2 pb-">
                    <button
                      className="btn btn-dark btn-lg btn-block rounded-full position"
                      disabled={isUpdating}
                      onClick={toggleItemStatus}
                    >
                      {itemStatus.status === 'active' ?
                        <>
                          <ion-icon name="remove-circle-outline"></ion-icon>
                          &nbsp; Mark product out of stock
                        </>
                        :
                        <>
                          <ion-icon name="checkmark-circle-outline"></ion-icon>
                          &nbsp; Mark product back in stock
                        </>
                      }
                    </button>
                  </div>
                </div>

              </div>
            </div>
          ) : (
            <div className="d-flex  justify-content-center align-items-center  ItemDetail pt-5 w-100 h-100">
              <div className="card p-5 m-5 display-flex flex-row w-75 h-100">
                {item.image_url && (
                  <div className="p-1 h-100">
                    <img
                      src={item.image_url}
                      className="rounded shadow item-detail-product-img"
                      alt="product "
                      height="300px"
                      width="300px"
                    />
                  </div>
                )}
                <div className="card-body">
                  <h1>{item.name}</h1>
                  <p className="card-text">{item.description}</p>
                  <hr />
                  <h3>
                    Select the items you'd like to set as 'out of stock'. If the
                    entire item is unavailable, tap the black button to
                    mark it as such.
                  </h3>
                  <b style={{color:"red"}}>BY SELECTING "Mark product as 'out of stock' you are marking the entire product out of stock!</b>
                  <div className="section full">
                    <div className="pt-2 pb-2">
                      <div className="section full">
                        <div className=" pt-2 pb-2">
                          <button
                            className="btn btn-dark btn-lg btn-block rounded-full position"
                            disabled={isUpdating}
                            onClick={toggleItemStatus}>
                            {itemStatus.status === 'active' ?
                              <>
                                <ion-icon name="remove-circle-outline"></ion-icon>
                                &nbsp; Mark product out of stock
                              </>
                              :
                              <>
                                <ion-icon name="checkmark-circle-outline"></ion-icon>
                                &nbsp; Mark product back in stock
                              </>
                            }
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                  
                  <hr />
                  {item.variants &&
                    item.variants.data &&
                    item.variants.data.length >= 1
                    ? item.variants.data.map((variant) => {

                      return (

                        <div
                          className="form-check form-switch mb-1 vendor-switch mb-1"
                          key={`variant-${variant.id}`}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input variants"
                            name="variant_id"
                            data-id={variant.id}
                            id={`variant-${variant.id}`}
                            disabled={isUpdating}
                            onChange={(e) => handlecheckBoxChange(e, variant.id, "variant")}

                            checked={checkedStatus.has(variant.id) ? checkedStatus.get(variant.id) : variant.status === "active"}

                          />
                          <label
                            className="form-check-label"
                            htmlFor={`variant-${variant.id}`}
                          >
                            <b className="fs-4 pb-1 ">{variant.name}</b>
                            <br />
                          </label>
                        </div>
                      );
                    })
                    : null}

                  {optionGroups &&
                    optionGroups.data &&
                    optionGroups.data.length >= 1 ? (
                    <div className="section full m-1 ">
                      <hr />
                      <h5 className="card-title">Options / Extras</h5>
                      {optionGroups.data.map((optionGroup) => {

                        return (
                          <Fragment key={`option-group-${optionGroup.id}`}>
                            <h5 className="card-title fs-5 pt-2">
                              {optionGroup.name}
                            </h5>
                            {optionGroup.options.data.map((option) => {
                              return (
                                <div
                                  className="form-check form-switch mb-1 vendor-switch "
                                  key={`option-${option.id}`}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`option-${option.id}`}
                                    disabled={isUpdating}
                                    onChange={(e) => handlecheckBoxChange(e, option.id, "option")}

                                    checked={checkedStatus.has(option.id) ? checkedStatus.get(option.id) : option.status === "active"}

                                  />

                                  <label
                                    className="form-check-label"
                                    htmlFor={`option-${option.id}`}
                                  >
                                    <div>
                                      <b className="fs-5 pb-1 ">
                                        {option.name}
                                      </b>{" "}
                                      <br />
                                    </div>
                                  </label>
                                </div>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                      <hr />
                    </div>

                  ) : null}

                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <NotificationsBar />
      <BottomMenu />
      <Footer />
      <SidebarPanel />
    </Fragment>
  );
};

export default ItemDetailsPage;
