import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();

    const refreshPage = () => {
        window.location.reload(false);
    }

    const goBack = () => {
        navigate(-1);
    }

    return (
        
        <Fragment>
            <div className="appHeader no-border transparent position-absolute">
                <div className="left">
                    <Link onClick={ goBack } className="headerButton goBack">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </Link>
                </div>
                <div className="pageTitle">Error Page</div>
                <div className="right">
                    <Link to="/" className="headerButton">
                        Done
                    </Link>
                </div>
            </div>

            <div id="appCapsule">

                <div className="error-page">
                    <div className="icon-box text-danger">
                        <ion-icon name="alert-circle"></ion-icon>
                    </div>
                    <h1 className="title">Not Found</h1>
                    <div className="text mb-5">
                        We couldn't find what you were looking for, but while you're here you might as well enjoy this quote.
                    </div>
                    
                    <blockquote className="blockquote">
                        <p>When wealth is lost, nothing is lost; when health is lost, something is lost; when character is lost, all is lost.</p>
                    </blockquote>

                    <div className="fixed-footer">
                        <div className="row">
                            <div className="col-6">
                                <button className="btn btn-secondary btn-lg btn-block" onClick={ goBack }>Go Back</button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-primary btn-lg btn-block" onClick={ refreshPage }>Try Again</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    );
}

export default ErrorPage