import React from "react";

const Loader = () => {
    return (
        <div className="py-5 text-center " >
            <div className="spinner-border text-primary" role="status"></div>
        </div>
    );
}

export default Loader;