import axios from 'axios';
import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setToast } from '../redux/features/app/globalSlice';
import { useDispatch } from 'react-redux';

const PasswordResetPage = () => {
    const { token } = useParams();
    const [ searchParams ] = useSearchParams();

    const [ busy, setBusy ] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm()
    const navigate = useNavigate()

    const dispatch = useDispatch();

    const onSubmit = (data) => {
        setBusy(true);

        data.token = token;

        axios.post('/password-reset/' + token, data)
          .then((response) => {
            var toast = {
                content: response.data.message,
                color: 'primary'
            };
            dispatch(setToast(toast));

            setBusy(false);
            navigate('/');
          })
          .catch((error) => {
            console.log(error.response.data); 
            setBusy(false);

            var toast = {
                content: error.response.data.message,
                color: 'danger'
            };
            dispatch(setToast(toast));
          });
    }

    return (
        <Fragment>
            <div className="appHeader no-border transparent position-absolute">
     
                <div className="pageTitle"></div>
                <div className="right">
                    <Link to="/login" className="headerButton">
                        Login
                    </Link>
                </div>
            </div>

            <div id="appCapsule">
                <div className="login-form">
                    <div className="section">
                        <img src="/assets/img/logo.png" alt="" className="form-image" />
                    </div>
                    <div className="section">
                        <h1>Reset Password</h1>
                        <h4>Type and confirm your new password.</h4>
                    </div>
                    <div className="section mt-2 mb-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            
                            <input type="hidden" { ...register('email')} value={searchParams.get('email')} />

                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input type="password" className={`form-control ${errors.password ? "is-invalid" : ""}`} { ...register('password', {
                                        required: "Password is required.",
                                        minLength: {
                                            value: 6,
                                            message: "Password should be at-least 6 characters."
                                        }
                                    })} autoComplete="off" placeholder="Password" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                    {errors.password && <div className="invalid-feedback text-start px-2">{errors.password.message}</div>}
                                </div>
                            </div>

                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input type="password" className={`form-control ${errors.password_confirmation ? "is-invalid" : ""}`} { ...register('password_confirmation', { required: 'Password confirmation is required' })} autoComplete="off" placeholder="Password (again)" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                    {errors.password_confirmation && <div className="invalid-feedback text-start px-2">{errors.password_confirmation.message}</div>}
                                </div>
                            </div>

                            <div className="form-button-group">
                                <button type="submit" className="btn btn-dark btn-block btn-lg" disabled={busy} >Reset</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default PasswordResetPage