import { createSlice } from '@reduxjs/toolkit';
import { fetchActiveVenueAction } from './globalActions';

const globalSlice = createSlice({
    name: "global",    
    initialState: {
        loading: false,
        error: null,
        activeVenueId: null,
        activeVenue: null,
        toast: null,
        serviceWorkerRegistrationUpdated: false,
        serviceWorkerRegistration: null
    },    
    reducers: {
        clearActiveVenue: (state) => {
            state.activeVenueId = null;
            state.activeVenue = null;
        },
        setActiveVenueId: (state, action) => {
            state.activeVenueId = action.payload;
        },
        setToast: (state, action) => {
            state.toast = action.payload
        },
        setServiceWorkerRegistrationUpdated: (state, action) => {
            state.serviceWorkerRegistrationUpdated = true;
            state.serviceWorkerRegistration = action.payload;
        }
    },
    extraReducers: {
        [fetchActiveVenueAction.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [fetchActiveVenueAction.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.activeVenue = payload;
        },
        [fetchActiveVenueAction.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        }
    }
});

const { actions, reducer } = globalSlice

export const { setActiveVenueId, clearActiveVenue, setToast, setServiceWorkerRegistrationUpdated} = actions;

export const getActiveVenue = (state, venueId) => {
    if (state.activeVenue && state.activeVenue.id === venueId) {
        return state.activeVenue;
    } else {
        return null;
    }
}

export default reducer;