import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { registerUser } from '../redux/features/auth/authActions';
import { setToast } from '../redux/features/app/globalSlice';
import { Link } from 'react-router-dom';

const Register = () => {
    const dispatch = useDispatch()  
    const { register, handleSubmit, formState: { errors } } = useForm()

    const { loading, error } = useSelector(
      (state) => state.auth
    );

    const onSubmit = (data) => {
      dispatch(registerUser(data))
    }

    useEffect(() => {
      if (error) {
        var toast = {
          content: error,
          color: 'danger'
        };
        dispatch(setToast(toast));
      }
    }, [error, dispatch]);

    return (
     <div className="login-background position-relative">
        <Link to="/" className="rounded-full  login-back  btn btn-primary text-white m-1 shadow-lg" >
             <ion-icon name="chevron-back-outline"></ion-icon> 
                    </Link>
                    <div className="login-form " >
                    <div className="section ">
                <img src="/assets/img/logo.png" alt="" className="form-image" />
            </div>
          <div className="section mt-1">
           
          </div>
         
 <div className=" mt-1 p-sm-5 p-md-3 absolute-bottom  shadow bg-white login-top-rounded fade-up  d-flex justify-content-center flex-column">
                 <h1>Register</h1>
              <h4>Fill the form to join us</h4>
              <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="form-group boxed">
                      <div className="input-wrapper">
                          <input type="text" className={` rounded-full form-control ${errors.name ? "is-invalid" : ""}`} { ...register('name', { required: 'Name is required' })} placeholder="Name" />
                          <i className="clear-input">
                              <ion-icon name="close-circle"></ion-icon>
                          </i>
                          {errors.name && <div className="invalid-feedback text-start px-2">{errors.name.message}</div>}
                      </div>
                  </div>

                  <div className="form-group boxed">
                      <div className="input-wrapper">
                          <input type="email" className={` rounded-full form-control ${errors.email ? "is-invalid" : ""}`} { ...register('email', { 
                              required: 'Email is required', pattern: {
                                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/, message: 'Email is not valid'
                              } 
                          })} placeholder="Email address" />
                          <i className="clear-input">
                              <ion-icon name="close-circle"></ion-icon>
                          </i>
                          {errors.email && <div className="invalid-feedback text-start px-2">{errors.email.message}</div>}
                      </div>
                  </div>

                  <div className="form-group boxed">
                      <div className="input-wrapper">
                          <input type="password" className={`form-control rounded-full ${errors.password ? "is-invalid" : ""}`} { ...register('password', {
                              required: "Password is required.",
                              minLength: {
                                  value: 6,
                                  message: "Password should be at-least 6 characters."
                              }
                          })} autoComplete="off" placeholder="Password" />
                          <i className="clear-input">
                              <ion-icon name="close-circle"></ion-icon>
                          </i>
                          {errors.password && <div className="invalid-feedback text-start px-2">{errors.password.message}</div>}
                      </div>
                  </div>

                  <div className="form-group boxed">
                      <div className="input-wrapper">
                          <input type="password" className={`form-control ${errors.password_confirmation ? "is-invalid" : ""}`} { ...register('password_confirmation', { required: 'Password confirmation is required' })} autoComplete="off" placeholder="Password (again)" />
                          <i className="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                          </i>
                          {errors.password_confirmation && <div className="invalid-feedback text-start px-2">{errors.password_confirmation.message}</div>}
                      </div>
                  </div>

                
                  {/* <div className="form-group mt-2 mb-3">
                      <div className="form-check mb-1">
                          <input type="checkbox" className={`form-check-input ${errors.terms_and_conditions ? "is-invalid" : ""}`} { ...register('terms_and_confitions', { required: 'You need to confirm the terms and conditions.' })} id="terms_and_conditions" />
                          <label className="form-check-label" htmlFor="terms_and_conditions">
                              Agree to terms and conditions
                          </label>
                          {errors.terms_and_conditions && <div className="invalid-feedback text-start px-2">{errors.terms_and_conditions.message}</div>}
                      </div>
                  </div> */}

                  <div className="form-button-group">
                      <button type="submit" className="btn alt-bg-color btn-block btn-lg rounded-full" disabled={loading} >Register</button>
                  </div>
              </form>
          </div>
      </div>
      </div>
    );
}

export default Register