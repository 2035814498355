import { configureStore } from '@reduxjs/toolkit'

import globalReducer from './features/app/globalSlice';
import authReducer from './features/auth/authSlice';
import cartReducer from './features/cart/cartSlice';
import queuesReducer from './features/queues/queuesSlice';

export default configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    cart: cartReducer,
    queues: queuesReducer
  }
});