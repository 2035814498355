import { createSlice } from '@reduxjs/toolkit'
import { fetchActiveCartAction } from './cartActions';

const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {};

const initialState = {
  loading: false,
  data: cart, 
  error: null
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      const cart = action.payload;      

      localStorage.setItem('cart', JSON.stringify(cart));

      state = cart;
    },
    removeFromCart: (state) => {
      console.log('removeFromCart');
    },
    clearCart: (state, action) => {
      console.log('clearCart');
    },
    setCartItemCount: (state, action) => {
      console.log('setCartItemCount');
    },
  },
  extraReducers: {
    // login action
    [fetchActiveCartAction.pending]: (state) => {
      // Set cart state here
      state.loading = true;
    },
    [fetchActiveCartAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchActiveCartAction.rejected]: (state, action) => {
      state.loading = false;
      state.data = {};
    },
  }
})

export const { updateCart, removeFromCart, clearCart } = cartSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(updateCart())
//   }, 1000)
// }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCart = (state) => state.cart

export const selectCartItemCount = (state) => {
  let itemCount = 0;

  let data = { ...state.cart.data }

  if (data && data.orders && data.orders.data) {
    var orders = data.orders.data;
    orders.forEach((order) => {
        if (order.order_items && order.order_items.data){
            var orderItems = order.order_items.data;
            orderItems.forEach((orderItem) => {
                itemCount = itemCount + orderItem.quantity;
            });
        }
    });
  }

  return itemCount;
}

export default cartSlice.reducer
  