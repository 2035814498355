import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import BottomMenu from "../common/BottomMenu";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import NotificationsBar from "../common/NotificationsBar";
import AppHeader from "../common/AppHeader";
import api from "../services/api.service";
import Loader from "../common/Loader";
import { fetchActiveCartAction } from "../redux/features/cart/cartActions";
import { setActiveVenueId, setToast } from "../redux/features/app/globalSlice";
import { toggleShowAuthModal } from "../redux/features/auth/authSlice";

const ItemDetailsPage = () => {
  const { user } = useSelector((state) => state.auth);

  const { venueId, vendorId, itemId } = useParams();

  const [item, setItem] = useState({
    name: "Item",
  });

  const [optionGroups, setOptionGroups] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [venue, setVenue] = useState({});
  const [notes, setNotes] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [orderItem, setOrderItem] = useState({
    order: {
      delivery_method: localStorage.getItem(`vendors.${vendorId}.delivery_method`),
      delivery_method_type: localStorage.getItem(`vendors.${vendorId}.delivery_method_type`),
      delivery_method_location: localStorage.getItem(`vendors.${vendorId}.delivery_method_location`)
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    api
    .get(`/venues/${venueId}`)
    .then((response) => {
      setVenue(response.data.data);
    }).then(()=>
    {
      
    })
    .catch((error) => {
      console.error(error);
    });

    
    
    api
      .get("/items/" + itemId)
      .then((response) => {
        let data = response.data.data;
        setItem(data);
        setSelectedVariant(data.master);
        // Set the optionGroups from the item option groups and item group option groups
        var optionGroups = { data: [] };
        if (data.option_groups && data.option_groups.data) {
          optionGroups.data = optionGroups.data.concat(data.option_groups.data);
        }

        if (
          data.group &&
          data.group.option_groups &&
          data.group.option_groups.data
        ) {
          optionGroups.data = optionGroups.data.concat(
            data.group.option_groups.data
          );
        }

        setOptionGroups(optionGroups);
        setOrderItem({
          venue_id: venueId,
          vendor_id: vendorId,
          variant_id: data.master.id,
          quantity: 1,
          price: data.master.price,
          options_total: 0,
          item_total: data.master.price,
          total: data.master.price,
          options: [],
        });

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);

        console.error(error);
      });
  }, [itemId, navigate, venueId, vendorId]);

  const setSelectedVariantHandler = (e, variant) => {
    setSelectedVariant(variant);
  };

  const handleSelectOption = (e, option) => {
    if (e.target.checked) {
      let selectedOption = selectedOptions.find(
        (selectedOption) => selectedOption.id === option.id
      );
      if (!selectedOption) {
        setSelectedOptions([...selectedOptions, option]);
      }
    } else {
      const idx = selectedOptions.findIndex(
        (selectedOption) => selectedOption.id === option.id
      );
      if (idx > -1) {
        selectedOptions.splice(idx, 1);
      }

      setSelectedOptions([...selectedOptions]);
    }
  };

  const handleIncreaseQuantity = (e) => {
    e.preventDefault();
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = (e) => {
    e.preventDefault();

    const quantity = orderItem.quantity > 1 ? orderItem.quantity - 1 : 1;
    setQuantity(quantity);
  };

  const handleAddToCart = () => {
    if (!user) {
      dispatch(toggleShowAuthModal());
      return false;
    }

    setIsBusy(true);

    api
      .post("/carts", orderItem)
      .then((response) => {
        var toast = {
          content: "Added orderItem to cart",
          color: "success",
        };
        dispatch(setToast(toast));

        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        setIsBusy(false);
        setIsButtonClicked(true); // Set the state variable to true when the button is clicked
        navigate(-1);
      })
      .catch((error) => {
        setIsBusy(false);
      });
  };

  useEffect(() => {

    var item_total = parseFloat(selectedVariant.price) * quantity;

    let options_total = 0;
    selectedOptions.forEach((selectedOption) => {
      options_total =
        parseFloat(options_total) + parseFloat(selectedOption.price);
    });

    var total =
      (parseFloat(selectedVariant.price) + parseFloat(options_total)) *
      quantity;

    setOrderItem({
      venue_id: venueId,
      vendor_id: vendorId,
      variant_id: selectedVariant.id,
      quantity: quantity,
      price: selectedVariant.price,
      item_total: item_total.toFixed(2),
      total: total.toFixed(2),
      options_total: options_total.toFixed(),
      options: selectedOptions,
      notes: notes,
    });
  }, [
    selectedVariant.id,
    selectedVariant.price,
    quantity,
    selectedOptions,
    venueId,
    vendorId,
    notes,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(setActiveVenueId(venueId));
  }, [dispatch, venueId]);

  return (
    <Fragment>
      <AppHeader name={item.name}></AppHeader>

      {isLoading ? (
        <div id="appCapsule">
          <Loader />
        </div>
      ) : (
        <div id="appCapsule ">
          {windowWidth < 829 ? (
            <div className="d-flex  justify-content-center align-items-center flex-column ">
              <div className=" col-sm-12 ItemDetail">
                <div className="text-center"></div>

                <div className="card m-1 ">
                  <h1 className="card-title pt-3 px-5 mx-2 text-center">
                    {item.name}
                  </h1>
                  {item.image_url && (
                    <div className=" position-relative text-center">
                      <img
                        src={item.image_url}
                        className="rounded shadow product-img"
                        alt="product"
                      />
                    </div>
                  )}
                  <div className="card-body">
                    <p className="card-text">{item.description}</p>
                  </div>
                </div>

                <div className="section full  m-1">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Sizes / Variations</h5>
                      {item.variants && item.variants.data
                        ? item.variants.data
                          .filter((variant) => variant.status === "active")
                          .map((variant) => {
                            return (
                              <div
                                className="form-check mb-1"
                                key={`variant-${variant.id}`}
                              >
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  name="variant_id"
                                  id={`variant-${variant.id}`}
                                  value="1"
                                  checked={selectedVariant.id === variant.id}
                                  onChange={(e) =>
                                    setSelectedVariantHandler(e, variant)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`variant-${variant.id}`}
                                >
                                  <b className="fs-4 pb-1 ">{variant.name}</b>
                                  <br />
                                  <b className=" fs-6 text-success">
                                    {venue.currency_symbol} {variant.price}
                                  </b>
                                </label>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>

                {optionGroups &&
                  optionGroups.data &&
                  optionGroups.data.length > 0 ? (
                  <div className="section full m-1 ">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Options / Extras</h5>
                        {optionGroups.data.map((optionGroup) => {
                          return (
                            <Fragment key={`option-group-${optionGroup.id}`}>
                              <h3 className="card-title fs-4 pt-2">
                                {optionGroup.name}
                              </h3>
                              {optionGroup.options.data.filter(item => item.status === 'active').map((option) => {
                                return (
                                  <div
                                    className="form-check mb-1"
                                    key={`option-${option.id}`}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`option-${option.id}`}
                                      onChange={(e) =>
                                        handleSelectOption(e, option)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`option-${option.id}`}
                                    >
                                      <div>
                                        <b className="fs-5 pb-1 ">
                                          {option.name}
                                        </b>{" "}
                                        <br />
                                        <b className=" fs-6 text-success">
                                          {venue.currency_symbol} {option.price}
                                        </b>
                                      </div>
                                    </label>
                                  </div>
                                );
                              })}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="section full m-1 ">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Notes / Instructions</h5>
                      <textarea
                        className="form-control"
                        rows="3"
                        onChange={(e) => setNotes(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                {orderItem && (
                  <div className="section full">
                    <div className="wide-block pt-2 pb-2 product-detail-header">
                      <div className="detail-footer">
                        <div className="price w-100">
                          <div className="current-price text-dark text-start ">
                          {venue.currency_symbol} {orderItem.total}
                          </div>
                        </div>
                        <div className="amount">
                          <div className="stepper stepper-sm stepper-dark">
                            <Link
                              to="!#"
                              className="stepper-button stepper-down w-25"
                              onClick={(e) => handleDecreaseQuantity(e)}
                            >
                              -
                            </Link>
                            <input
                              type="text"
                              className="form-control w-100"
                              value={orderItem.quantity}
                              disabled
                            />
                            <Link
                              to="!#"
                              className="stepper-button stepper-up w-25"
                              onClick={(e) => handleIncreaseQuantity(e)}
                            >
                              +
                            </Link>
                          </div>
                        </div>
                      </div>

                      {!isButtonClicked && (
                        <button
                          className="btn btn-primary btn-lg btn-block rounded-full mb-1"
                          onClick={(e) => handleAddToCart(e)}
                          disabled={isBusy}
                        >
                          <ion-icon name="bag-add"></ion-icon>
                          &nbsp; Add to cart
                        </button>
                      )}
                      {isButtonClicked && (
                        <Link
                          className="btn btn-primary btn-lg btn-block rounded-full mb-1 position"
                          to={`/venues/${venueId}/cart`}
                        >
                          <ion-icon name="bag-check"></ion-icon> &nbsp; Go to
                          cart
                        </Link>
                      )}
                      <button
                        className="btn btn-dark btn-lg btn-block rounded-full mb-1 position"
                        onClick={() => navigate(-1)}
                      >
                        <ion-icon name="fast-food"></ion-icon> &nbsp; Continue
                        shopping
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="">
              <div className="d-flex  w-100 justify-content-center align-items-center  ItemDetail pt-5 w-100 h-100">
                <div className="card w-75 p-5 mx-3 mb-1 display-flex flex-row h-100">
                  {item.image_url && (
                    <div className="p-1 h-100">
                      <img
                        src={item.image_url}
                        className="rounded shadow item-detail-product-img "
                        alt="product "
                      />
                    </div>
                  )}
                  <div className="card-body">
                    <h1>{item.name}</h1>
                    <p className="card-text">{item.description}</p>
                    {item.variants &&
                      item.variants.data &&
                      item.variants.data.length > 0
                      ? item.variants.data.filter(item => item.status === 'active').map((variant) => {
                        return (
                          <div
                            className="form-check mb-1"
                            key={`variant-${variant.id}`}
                          >
                            <input
                              type="radio"
                              className="form-check-input"
                              name="variant_id"
                              id={`variant-${variant.id}`}
                              value="1"
                              checked={selectedVariant.id === variant.id}
                              onChange={(e) =>
                                setSelectedVariantHandler(e, variant)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`variant-${variant.id}`}
                            >
                              <b className="fs-4 pb-1 ">{variant.name}</b>
                              <br />
                              <b className=" fs-6 text-success">
                              {venue.currency_symbol} {variant.price}
                              </b>
                            </label>
                          </div>
                        );
                      })
                      : null}

                    {optionGroups &&
                      optionGroups.data &&
                      optionGroups.data.length > 0 ? (
                      <div className="section full m-1 ">
                        <hr />
                        <h5 className="card-title">Options / Extras</h5>
                        {optionGroups.data.map((optionGroup) => {
                          return (
                            <Fragment key={`option-group-${optionGroup.id}`}>
                              <h5 className="card-title fs-5 pt-2">
                                {optionGroup.name}
                              </h5>
                              {optionGroup.options.data.filter(item => item.status === 'active').map((option) => {
                                return (
                                  <div
                                    className="form-check mb-1"
                                    key={`option-${option.id}`}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`option-${option.id}`}
                                      onChange={(e) =>
                                        handleSelectOption(e, option)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`option-${option.id}`}
                                    >
                                      <div>
                                        <b className="fs-5 pb-1 ">
                                          {option.name}
                                        </b>{" "}
                                        <br />
                                        <b className=" fs-6 text-success">
                                        {venue.currency_symbol} {option.price}
                                        </b>
                                      </div>
                                    </label>
                                  </div>
                                );
                              })}
                            </Fragment>
                          );
                        })}
                      </div>
                    ) : null}
                    <hr />
                    <div>
                      <h5 className="card-title">Notes / Instructions</h5>
                      <textarea
                        className="form-control"
                        rows="3"
                        onChange={(e) => setNotes(e.target.value)}
                      ></textarea>
                    </div>
                    {orderItem && (
                      <div className="section full">
                        <div className="wide-block pt-2 pb-2 product-detail-header">
                          <div className="detail-footer">
                            <div className="price w-100">
                              <div className="current-price text-dark text-start">
                              {venue.currency_symbol} {orderItem.total}
                              </div>
                            </div>
                            <div className="amount">
                              <div className="stepper stepper-sm stepper-dark">
                                <Link
                                  to="!#"
                                  className="stepper-button stepper-down"
                                  onClick={(e) => handleDecreaseQuantity(e)}
                                >
                                  -
                                </Link>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={orderItem.quantity}
                                  disabled
                                />
                                <Link
                                  to="!#"
                                  className="stepper-button stepper-up"
                                  onClick={(e) => handleIncreaseQuantity(e)}
                                >
                                  +
                                </Link>
                              </div>
                            </div>
                          </div>

                          {!isButtonClicked && (
                            <button
                              className="btn btn-primary btn-lg btn-block rounded-full mb-1"
                              onClick={(e) => handleAddToCart(e)}
                              disabled={isBusy}
                            >
                              <ion-icon name="bag-add"></ion-icon>
                              &nbsp; Add to cart
                            </button>
                          )}
                          {isButtonClicked && (
                            <Link
                              className="btn btn-primary btn-lg btn-block rounded-full mb-1 position"
                              to={`/venues/${venueId}/cart`}
                            >
                              <ion-icon name="bag-check"></ion-icon> &nbsp; Go to
                              cart
                            </Link>
                          )}
                          <button
                            className="btn btn-dark btn-lg btn-block rounded-full mb-1 position"
                            onClick={() => navigate(-1)}
                          >
                            <ion-icon name="fast-food"></ion-icon> &nbsp; Continue
                            shopping
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>


          )}
        </div>
      )}
      <NotificationsBar />
      <BottomMenu />
      <Footer />
      <SidebarPanel />
    </Fragment>
  );
};

export default ItemDetailsPage;
