import React from 'react'
import Login from './Login';
import { useSelector } from 'react-redux';
import Register from './Register';
import ForgotPassword from './ForgotPassword';

const Auth = () => {
  const { authModalAs } = useSelector((state) => state.auth);

  return (
    <>
      {
        authModalAs === 'Login' &&
          <Login ></Login>
      }

      {
        authModalAs === 'Register' &&
          <Register ></Register>
      }

      {
        authModalAs === 'ForgotPassword' &&
          <ForgotPassword ></ForgotPassword>
      }
    </>
    
  )
}

export default Auth;
