import React, { Component } from 'react'

let company_name = process.env.REACT_APP_COMPANYNAME;
export class Footer extends Component {

        company_name
        constructor(props) {
          super(props);
          this.state = {
            isScrolled: false
          }
        }
      
        componentDidMount() {
          window.addEventListener('scroll', this.handleScroll);
        }
      
        componentWillUnmount() {
          window.removeEventListener('scroll', this.handleScroll);
        }
      
        handleScroll = () => {
          const isScrolled = window.scrollY > 20;
          this.setState({ isScrolled });
        };
      
        scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        };
    
        
  render() {
    return (
        <div className="appFooter">
           
          <img src="/assets/img/logo.png" alt="icon" className="footer-logo mb-2" style={{ width: '40px', height: 'auto' }} />
            <div className="footer-title">
                Copyright © {company_name} <span className="yearNow"></span>. All Rights Reserved.
            </div>
            <div >'Coz life's too short to stand in queues</div>

            {/* <div className="mt-2">
                <a href="!#" className="btn btn-icon btn-sm btn-facebook">
                    <ion-icon name="logo-facebook"></ion-icon>
                </a>
                <a href="!#" className="btn btn-icon btn-sm btn-twitter">
                    <ion-icon name="logo-twitter"></ion-icon>
                </a>
                <a href="!#" className="btn btn-icon btn-sm btn-linkedin">
                    <ion-icon name="logo-linkedin"></ion-icon>
                </a>
                <a href="!#" className="btn btn-icon btn-sm btn-instagram">
                    <ion-icon name="logo-instagram"></ion-icon>
                </a>
                <a href="!#" className="btn btn-icon btn-sm btn-whatsapp">
                    <ion-icon name="logo-whatsapp"></ion-icon>
                </a>
                <a href="!#" className="btn btn-icon btn-sm btn-secondary goTop">
                    <ion-icon name="arrow-up-outline"></ion-icon>
                </a>
            </div> */}
        </div>
    )
  }
}

export default Footer