import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BottomMenu from "../common/BottomMenu";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import AppHeader from "../common/AppHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/Loader";
import api from "../services/api.service";
import { fetchActiveCartAction } from "../redux/features/cart/cartActions";
import { selectCartItemCount } from "../redux/features/cart/cartSlice";
import { usePaystackPayment } from "react-paystack";
import { setActiveVenueId } from "../redux/features/app/globalSlice";
import NotificationsBar from "../common/NotificationsBar";
import OrderCollectionDelivery from "../components/OrderCollectionDelivery";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import PaystackPop from "@paystack/inline-js";

//const stripePromise = loadStripe('pk_test_51O4RLNIASfrQ7wYLPnrFxQmBxo8mNAuFcoPSYKX9PWHdZ1VEck8pnePwzpKhGiwzhGrAFToXJJrnWOvE8czgsNhs00OWuFr2At');

let promise = "";
let isStripeFormVisible = false;
let showHST = process.env.HASHST;
let stripePromise = null;

const ParentComponent = (props) => {
  if (!promise) return;

  function myCallback(reference) {
    props.callback(reference);
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret: promise,
  };
  return (
    <div>
      {!isStripeFormVisible && <div> Loading please wait ...</div>}
      {isStripeFormVisible && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm callback={myCallback} />
        </Elements>
      )}
    </div>
  );
};

var HstTotal = (props) => {
  console.log("HST", props);

  let hstCart = props.cart;
  let symbol = props.symbol;
  return (
    <>
      {/* <li >
      Service fee <span className="text-success">$ {((+1)).toFixed(2)}</span>
    </li> */}
      <li>
        {/* HST <span className="text-success">$ {((+cart.item_total + 1) * .13).toFixed(2)}</span> */}
        HST{" "}
        <span className="text-success">
          {symbol} {(+hstCart.item_total * 0.13).toFixed(2)}
        </span>
      </li>
      <li className=" fw-bold ">
        {/* Total<span className="text-success">$ {((cart.item_total + 1) * 1.13).toFixed(2)}</span> */}
        Total
        <span className="text-success">
          {symbol} {(hstCart.item_total * 1.13).toFixed(2)}
        </span>
      </li>
    </>
  );
};

var NoHstTotal = (props) => {
  let hstCart = props.cart;
  let symbol = props.symbol;
  return (
    <>
      {/* <li className=" fw-bold ">
      Service fee<span className="text-success">$ {(+1).toFixed(2)}</span>
    </li> */}
      <li className=" fw-bold ">
        {/* Total<span className="text-success">$ {(+cart.item_total +1).toFixed(2)}</span> */}
        Total
        <span className="text-success">
          {symbol} {(+hstCart.item_total).toFixed(2)}
        </span>
      </li>
    </>
  );
};

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, [stripe]);

  //onGetCS();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000",
      },
      redirect: "if_required",
    });

    if (
      error &&
      (error.type === "card_error" || error.type === "validation_error")
    ) {
      setMessage(error.message);
      setIsLoading(false);
    }

    if (paymentIntent && paymentIntent.status === "succeeded") {
      props.callback(paymentIntent.id);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-black mb-4">Complete your payment here!</p>
      <PaymentElement />
      <button
        className="btn btn-dark  btn-lg btn-block rounded-full mb-1"
        // onClick={(e) => alert("handlePayWithPayStack(e)")}
        disabled={isLoading || !stripe || !elements}
      >
        <ion-icon name="card"></ion-icon> &nbsp;{" "}
        {isLoading ? "Processing..." : "Pay now"}
      </button>
      {/* <button className='bg-black rounded-xl text-white p-2 mt-6 mb-2' disabled={isLoading || !stripe || !elements}>
        {isLoading ? "Loading..." : "Pay now"}
      </button> */}
      {message && <div>{message}</div>}
    </form>
  );
};

const CartDetailsPage = () => {
  const { venueId } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const cartItemCount = useSelector(selectCartItemCount);
  const isLoading = useSelector((state) => state.cart.loading);
  const cart = useSelector((state) => state.cart.data);

  const [isBusy, setIsBusy] = useState(false);
  const [isStripeActive, setIsStripeActive] = useState(false);
  const [venue, setVenue] = useState((venueTemp) => venueTemp);
  const [order_ready, setOrderReady] = useState(false);
  const dispatch = useDispatch();
  const [isNewPayment, setIsNewPayment] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    api
      .get(`/venues/${venueId}`)
      .then((response) => {
        setVenue(response.data.data);
        if (response.data.data.payment_provider === "Stripe") {
          stripePromise = loadStripe(response.data.data.payment_provider_key);
        } else {
          if (cart?.id && response.data.data.payment_provider === "Paystack") {
            var subaccounts = [];

            if (cart.orders && cart.orders.data) {
              subaccounts = cart.orders.data.map((order) => {
                var subaccountData = {
                  subaccount: order.vendor.paystack_account?.subaccount_code,
                  share: parseInt(order.total * 100),
                };

                return subaccountData;
              });
            }

            setOrderReady(false);
            // if (isNewPayment) {
            //   api
            //     .get("/carts/newpayment", {
            //       params: { cart_id: cart?.id, provider: "Paystack" },
            //     })
            //     .then((paymentresponse) => {
            //       setIsNewPayment(false);
            //       var config = {
            //         reference: paymentresponse.data.source_id,
            //         email: cart.account.email,
            //         amount: parseInt(cart.item_total * 100),
            //         split: {
            //           type: "flat",
            //           bearer_type: "all-proportional",
            //           subaccounts: subaccounts,
            //         },
            //         currency: "ZAR",
            //         container: "paystack-apple-pay", // ID of div to mount payment button elements
            //         loadPaystackCheckButton: "paystack-other-channels", // ID of button to trigger opening Paystack checkout (optional)

            //         // channels: [
            //         //   "card",
            //         //   "bank",
            //         //   "ussd",
            //         //   "qr",
            //         //   "mobile_money",
            //         //   "bank_transfer",
            //         //   "eft"
            //         // ],
            //         key: response.data.data.payment_provider_key, //process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
            //         onSuccess: onPaystackSuccess,
            //         onCancel: onClose,
            //       };
            //       setPayStackConfig(config);
            //       setOrderReady(true);
            //     });
            // }
          }
        }
      })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
    setTimeout(() => {
      dispatch(fetchActiveCartAction({ venue_id: venueId, user_id: user.id }));
      setIsNewPayment(true);
    }, 500);
  }, [venueId, user, navigate, dispatch]);

  const handleIncreaseQuantity = (e, order_item) => {
    e.preventDefault();

    setIsBusy(true);
    setIsStripeActive(false);
    const quantity = order_item.quantity + 1;

    api
      .put("/order-items/" + order_item.id, { quantity: quantity })
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        setIsBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsBusy(false);
      });
  };

  const handleDecreaseQuantity = (e, order_item) => {
    e.preventDefault();

    if (order_item.quantity < 2) {
      return false;
    }

    setIsBusy(true);
    setIsStripeActive(false);

    const quantity = order_item.quantity - 1;

    api
      .put("/order-items/" + order_item.id, { quantity: quantity })
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        setIsBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsBusy(false);
      });
  };

  const handleDeleteOrderItem = (e, order_item) => {
    setIsBusy(true);
    setIsStripeActive(false);
    api
      .delete("/order-items/" + order_item.id)
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        setIsBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsBusy(false);
      });
  };

  // const handlePayNow = (e) => {
  //     e.preventDefault();

  //     api
  //         .post(`/carts/${cartId}/pay-now`)
  //         .then((response) => {
  //             window.location.replace(response.data.data);
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  // const handleOrderNow = (e) => {
  //     e.preventDefault();

  //     api
  //         .post(`/carts/${cart.id}/order-now`)
  //         .then((response) => {
  //             dispatch(fetchActiveCartAction({venue_id: venueId, user_id: user.id}));

  //             navigate('/queues');
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  const [payStackConfig, setPayStackConfig] = useState({});
  const [isFormBusy, setIsFormBusy] = useState(false);

  useEffect(() => {
    setIsFormBusy(false);
    if (cart?.id && venue?.payment_provider === "Paystack") {
      var subaccounts = [];

      if (cart.orders && cart.orders.data) {
        subaccounts = cart.orders.data.map((order) => {
          var subaccountData = {
            subaccount: order.vendor.paystack_account?.subaccount_code,
            share: parseInt(order.total * 100),
          };

          return subaccountData;
        });
      }
      if (isNewPayment) {
        setOrderReady(false);
        console.log("Number 1a");
  
        setIsNewPayment(false);
        console.log("Number 1");
        api
          .get("/carts/newpayment", {
            params: { cart_id: cart?.id, provider: "Paystack" },
          })
          .then((paymentresponse) => {
            var config = {
              reference: paymentresponse.data.source_id,
              email: cart.account.email,
              amount: parseInt(cart.item_total * 100),
              split: {
                type: "flat",
                bearer_type: "all-proportional",
                subaccounts: subaccounts,
              },
              currency: "ZAR",
              container: "paystack-apple-pay", // ID of div to mount payment button elements
              loadPaystackCheckButton: "paystack-other-channels", // ID of button to trigger opening Paystack checkout (optional)

              // channels: [
              //   "card",
              //   "bank",
              //   "ussd",
              //   "qr",
              //   "mobile_money",
              //   "bank_transfer",
              //   "eft"
              // ],
              key: venue?.payment_provider_key, //process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
              onSuccess: onPaystackSuccess,
              onCancel: onClose,
            };
            setPayStackConfig(config);
            setOrderReady(true);
          });
      }
    }
  }, [cart, isNewPayment]);

  const onPaystackSuccess = (reference) => {
    var ref = reference;
    // ref.redirecturl = ref.redirecturl.split("--")[0]
    // ref.reference = ref.reference.split("--")[0];
    // ref.trxref = ref.trxref.split("--")[0];
    api
      .post(`/carts/${cart.id}/pay-with-paystack`, reference)
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );
        navigate(`/queues`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    if (venue.payment_provider === "Stripe") {
      api
        .post(`/carts/${cart.id}/pay-with-stripe`, { reference: reference })
        .then((response) => {
          dispatch(
            fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
          );

          navigate(`/queues`);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (venue.payment_provider === "Paystack") {
      api
        .post(`/carts/${cart.id}/pay-with-paystack`, reference)
        .then((response) => {
          dispatch(
            fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
          );

          navigate(`/queues`);
        })
        .catch((error) => {
          dispatch(
            fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
          );
          console.error(error);
        });
    }
  };

  // you can call this function anything
  const onClose = () => {
    //fetchActiveCartAction({ venue_id: venueId, user_id: user.id });
    setOrderReady(false);
    setIsNewPayment(true);
    dispatch(fetchActiveCartAction({ venue_id: venueId, user_id: user.id }));
    // cart.orders.data[0].id = '2D0C2B71-926C-4CCC-88BB-2D25054ACB5F';
    // alert("a")
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
  };

  function successCallback(reference) {
    api
      .post(`/carts/${cart.id}/pay-with-paystack`, "reference=" + reference)
      .then((response) => {
        dispatch(
          fetchActiveCartAction({ venue_id: venueId, user_id: user.id })
        );

        navigate(`/queues`);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // const initializePayment = usePaystackPayment(payStackConfig);
  const handlePayWithPayStack = async () => {
    const paystack = new PaystackPop();
    let psConfig = payStackConfig;
    await paystack.checkout(payStackConfig);
    //initializePayment(onSuccess, onClose);
  };

  // const PaystackHookExample = () => {

  //     api
  //         .post(`/carts/${cartId}/pay-now`)
  //         .then((response) => {

  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });

  //     const initializePayment = usePaystackPayment(config);
  //     return (
  //       <div>
  //           <button onClick={() => {
  //               initializePayment(onSuccess, onClose)
  //           }}>Paystack Hooks Implementation</button>
  //       </div>
  //     );
  // };
  const [isDeliveryValid, setIsDeliveryValid] = useState(false);

  const startStripe = () => {
    setIsStripeActive(true);
    onStartStripe();
    dispatch(setActiveVenueId(venueId));
  };

  const onStartStripe = () => {
    setIsBusy(true);
    promise = null;
    api
      .post(`/carts/${cart.id}/start-stripe`)
      .then((response) => {
        promise = response.data.secret.clientSecret;
        isStripeFormVisible = true;
        setIsBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsBusy(false);
      });
  };

  // useEffect(() => {

  //   // const onStartStripe = () => {
  //   //   setIsBusy(true);
  //   //   promise = null;
  //   //   api
  //   //     .post(`/carts/${cart.id}/start-stripe`)
  //   //     .then((response) => {
  //   //       promise = response.data.secret.clientSecret;
  //   //       isStripeFormVisible = true;
  //   //       setIsBusy(false);
  //   //     })
  //   //     .catch((error) => {
  //   //       console.error(error);
  //   //       setIsBusy(false);
  //   //     });
  //   // }
  //   dispatch(setActiveVenueId(venueId));
  //   //onStartStripe();
  // }, [dispatch, venueId]);

  const parentToChild = (formValid) => {
    //console.log("Form Valid",formValid);
    setIsDeliveryValid(formValid);
  };

  const formBusy = (busy) => {
    setIsFormBusy(busy);
    //console.log("Form Busy ", busy);
  };

  return (
    <Fragment>
      <AppHeader></AppHeader>
      <div id="appCapsule">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 col-sm-12">
            <div className="w-100 p-5 text-center">
              <div className="text-center">
                <img
                  src="/assets/img/logo.png"
                  className="img-fluid"
                  width="70"
                  alt="logo"
                />
              </div>
              <h1>Cart</h1>
            </div>

            {!cart && isLoading ? (
              <Loader />
            ) : cart.orders &&
              cart.orders.data &&
              cart.orders.data.length > 0 ? (
              <Fragment>
                {cart.orders.data.map((order) => {
                  return (
                    <div className="section m-1" key={`order-${order.id}`}>
                      <div className="card p-2">
                        <div className="fw-bold card-header p-0 pb-2">
                          {order.vendor.name}
                        </div>
                        <div className="card-body px-0 py-0">
                          {order.order_items &&
                          order.order_items.data &&
                          order.order_items.data.length > 0 ? (
                            <ul className="listview flush ">
                              {order.order_items.data.map((order_item) => {
                                return (
                                  <li
                                    className="p-2"
                                    key={`order-item-${order_item.id}`}
                                  >
                                    <div className="row mb-1">
                                      <div className="col">
                                        <div>
                                          {order_item.quantity} x{" "}
                                          {order_item.variant.item.name}
                                        </div>
                                      </div>
                                      <div className="col text-end">
                                        <b className="text-success">
                                          {venue?.currency_symbol}{" "}
                                          {order_item.total}
                                        </b>
                                      </div>
                                    </div>

                                    <div className="row mb-1">
                                      <div className="col">
                                        <div className="px-2">
                                          {order_item.variant.name} {" @ "}
                                          <b className="text-success">
                                            {venue?.currency_symbol}{" "}
                                            {order_item.price}
                                          </b>
                                        </div>
                                      </div>
                                    </div>

                                    {order_item.item_options &&
                                    order_item.item_options.data &&
                                    order_item.item_options.data.length > 0
                                      ? order_item.item_options.data.map(
                                          (item_option) => {
                                            return (
                                              <div
                                                className="row"
                                                key={`item-option-${item_option.id}`}
                                              >
                                                <div className="col">
                                                  <span className="px-2">
                                                    <i className="bi bi-plus-circle"></i>{" "}
                                                    {item_option.option.name}
                                                    {" @ "}
                                                    <b className="text-success">
                                                      {venue?.currency_symbol}{" "}
                                                      {item_option.price}
                                                    </b>
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}

                                    {order_item.notes && (
                                      <div className="card-text p-2">
                                        {order_item.notes}
                                      </div>
                                    )}

                                    <div
                                      className="row pt-1"
                                      disabled={isFormBusy}
                                    >
                                      <div className="col">
                                        <div className="stepper stepper-sm stepper-dark">
                                          <button
                                            className="stepper-button stepper-down "
                                            onClick={(e) =>
                                              handleDecreaseQuantity(
                                                e,
                                                order_item
                                              )
                                            }
                                            disabled={isBusy}
                                          >
                                            -
                                          </button>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={order_item.quantity}
                                            disabled
                                          />
                                          <button
                                            className="stepper-button stepper-up "
                                            onClick={(e) =>
                                              handleIncreaseQuantity(
                                                e,
                                                order_item
                                              )
                                            }
                                            disabled={isBusy}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col text-end">
                                        <div
                                          onClick={(e) =>
                                            handleDeleteOrderItem(e, order_item)
                                          }
                                          disabled={isBusy}
                                        >
                                          <ion-icon
                                            name="trash-bin"
                                            style={{
                                              color: "red",
                                              fontSize: "20px",
                                            }}
                                          ></ion-icon>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <p>No order items found</p>
                          )}
                        </div>
                        <div className="card-footer px-0 mt-1">
                          <OrderCollectionDelivery
                            style={{ display: "block", backgroundColor: "red" }}
                            order={order}
                            onHandleResult={(data) => parentToChild(data)}
                            onBusy={(data) => formBusy(data)}
                          ></OrderCollectionDelivery>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {!isDeliveryValid ? (
                  <div className="section m-1 ">
                    <h2
                      style={{
                        color: "#dc4220",
                      }}
                    >
                      Please select Delivery type...
                    </h2>
                  </div>
                ) : (
                  <></>
                )}
                <div className="section m-1">
                  <div className="card">
                    <ul className="listview flush transparent simple-listview p-3">
                      <li>
                        Items <span className="">{cartItemCount}</span>
                      </li>

                      {cart.adjustments &&
                      cart.adjustments.data &&
                      cart.adjustments.data.length > 0
                        ? cart.adjustments.data.map((adjustment) => {
                            return (
                              <li key={`adjustments.${adjustment.id}`}>
                                {adjustment.label}{" "}
                                <span className="text-success">
                                  {venue?.currency_symbol} {adjustment.amount}
                                </span>
                              </li>
                            );
                          })
                        : null}

                      {showHST ? (
                        <HstTotal cart={cart} symbol={venue?.currency_symbol} />
                      ) : (
                        <NoHstTotal
                          cart={cart}
                          symbol={venue?.currency_symbol}
                        />
                      )}

                      {/* {1==1 ? <hstTotal cart={cart} /> : null } */}
                      {/* <li >
                        HST <span className="text-success">$ {(cart.item_total * .13).toFixed(2)}</span>
                      </li>
                      <li className=" fw-bold ">
                        Total<span className="text-success">$ {(cart.item_total * 1.13).toFixed(2)}</span>
                      </li> */}
                    </ul>
                  </div>
                </div>

                {/* <div className="section mb-2">
                                    <button className="btn btn-dark btn-block " onClick={(e) => handleOrderNow(e)}  disabled={isBusy} >Order Now</button>
                                </div> */}
                {isDeliveryValid && !isBusy ? (
                  <div className="section m-1 ">
                    <br />

                    <div id="paystack-apple-pay"></div>
                    {!isStripeActive && venue?.payment_provider === "Stripe" ? (
                      <button
                        className="btn btn-dark btn-lg btn-block rounded-full mb-1 "
                        onClick={(e) => startStripe()}
                        disabled={isBusy}
                      >
                        Order Now
                      </button>
                    ) : null}
                    {venue?.payment_provider === "Paystack" &&
                    order_ready === true ? (
                      <div>
                        {" "}
                        <button
                          className="btn btn-dark  btn-lg btn-block rounded-full mb-1"
                          onClick={(e) => handlePayWithPayStack(e)}
                          disabled={isBusy}
                        >
                          <ion-icon name="card"></ion-icon> &nbsp; Pay Now{" "}
                        </button>
                      </div>
                    ) : null}
                    <br />
                    {isStripeActive && venue?.payment_provider === "Stripe" ? (
                      <div>
                        <ParentComponent callback={onSuccess} />
                      </div>
                    ) : null}
                    <Link
                      className="btn btn-primary btn-lg btn-block rounded-full mb-1"
                      to={`/venues/${venueId}/vendors`}
                    >
                      {order_ready}
                      <ion-icon name="fast-food"></ion-icon> &nbsp; Continue
                      Shopping
                    </Link>
                  </div>
                ) : (
                  <div className="section m-1 "></div>
                )}

                {/* <div className="section mb-2">
                                    <a href="!#" className="btn btn-dark btn-block " onClick={(e) => handlePayNow(e)} >Pay Now</a>
                                </div> */}
              </Fragment>
            ) : (
              <div className="section mt-3 mb-3">
                <div className="card">
                  <div className="card-body">
                    <p className="card-text">There is nothing in your cart.</p>
                  </div>
                  <div className="card-footer">
                    <Link
                      to={`/venues/${venueId}/vendors`}
                      className="btn btn-dark btn-block"
                    >
                      Continue shopping
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
      <NotificationsBar />
      <BottomMenu />
      <SidebarPanel />
    </Fragment>
  );
};

export default CartDetailsPage;
