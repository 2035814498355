import React, { Fragment } from 'react'
import ForgotPassword from '../components/ForgotPassword';

const ForgotPasswordPage = () => {
    
    return (
        <Fragment>
           
                <ForgotPassword ></ForgotPassword>
        
        </Fragment>
    );
}

export default ForgotPasswordPage