import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { setToast } from '../redux/features/app/globalSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
const ForgotPassword = () => {
    const [busy, setBusy] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm()
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        setBusy(true);
        
        axios.post('/forgot-password', data)
          .then((response) => {
            var toast = {
                content: response.data.message,
                color: 'primary'
            };
            dispatch(setToast(toast));

            setBusy(false);
          })
          .catch((error) => {
            setBusy(false);
            console.log(error);
          });
    }
    
    return (
        
<div className="login-background position-relative">
<Link to="/" className="rounded-full  btn btn-primary text-white m-1 shadow-lg" >
             <ion-icon name="chevron-back-outline"></ion-icon> 
                    </Link>
                    <div className="login-form " >
            <div className="section ">
                <img src="/assets/img/logo.png" alt="" className="form-image" />
            </div>
            <div className="section mt-1">
               
            </div>
            <div className=" mt-1 p-sm-5 p-md-3 absolute-bottom  shadow bg-white login-top-rounded fade-up  d-flex justify-content-center flex-column">
            <h1>Reset Password</h1>
                <h4>Type your email to reset your password</h4>
          
            <div className="section mt-2 mb-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group boxed">
                        <div className="input-wrapper">
                            <input type="email" className={`form-control rounded-full ${errors.email ? "is-invalid" : ""}`} { ...register('email', { 
                                required: 'Email is required', pattern: {
                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/, message: 'Email is not valid'
                                } 
                            })} placeholder="Email address" disabled={busy} />
                            <i className="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                            {errors.email && <div className="invalid-feedback text-start px-2">{errors.email.message}</div>}
                        </div>
                    </div>

                    <div className="form-button-group">
                        <button type="submit" className="btn btn-dark btn-block btn-lg rounded-full" disabled={busy} >Reset</button>
                    </div>
                </form>
            </div>
        </div>
        </div>
        </div>
    );
}

export default ForgotPassword;