import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api.service";

export const fetchActiveCartAction = createAsyncThunk('carts/active', async ({venue_id, user_id}, { rejectWithValue }) => {
  try {
    // const { data } = await api.get('/carts/active', { params: { venue_id: venue_id, user_id: user_id, state: 'new' } });
    const config = {
      headers: {
          'Content-Type': 'application/json',
      },
    }
    const { data } = await api.get('/carts/active', { params: { venue_id: venue_id, user_id: user_id, state: 'new' }}, config);

    localStorage.setItem('cart', JSON.stringify(data.data));
    data.data.paymentId = data.data.paymentnumber;//.id + "--" + getRandomInt(1,10).toString();
    return data.data;
  } catch (error) {
    // return custom error message from backend if present
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

// function getRandomInt(min, max) {
//   const minCeiled = Math.ceil(min);
//   const maxFloored = Math.floor(max);
//   return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
// }