import React, { useEffect, useState } from 'react'
import api from '../services/api.service';

const OrderCollectionDelivery = ({order, disabled = false, onHandleResult, onBusy, complete = false}) => {
    const [isBusy, setIsBusy] = useState(false);
    const [mode, setMode] = useState('edit');
    const [_order, setOrder] = useState(order);
    const [_errors, setErrors] = useState();
    const [_tableLocation, setTableLocation] = useState('');
  
    const handleOrderDeliveryMethodChange = (order, delivery_method) => {

      let delivery_method_type = order.delivery_method_type;
      if (delivery_method === 'collect') {
        delivery_method_type = 'collect';
      } else {
        delivery_method_type = '';
      }

      setOrder({
        ..._order, 
        delivery_method: delivery_method,
        delivery_method_type: delivery_method_type
      });
    };


    const handleOrderDeliveryMethodTypeChange = (order, delivery_method_type) => {
      setOrder({
        ..._order, 
        delivery_method_type: delivery_method_type,
        delivery_method_location: _tableLocation
      });
    };

    const handleOrderDeliveryMethodLocationChange = (order, delivery_method_location) => {
      setOrder({..._order, delivery_method_location: delivery_method_location});
    };

    const handleOrderDeliveryMethodInstructionsChange = (order, delivery_method_instructions) => {
      setOrder({..._order, delivery_method_instructions: delivery_method_instructions});
    };
    
    const handleCancel = () => {
      if (onHandleResult)
         onHandleResult(true);
      setOrder({
        ..._order, 
        delivery_method: order.delivery_method,
        delivery_method_type: order.delivery_method_type,
        delivery_method_location: order.delivery_method_location,
        delivery_method_instructions: order.delivery_method_instructions
      });

      setMode('view');
      onBusy(false);
    }

    const handleSubmit = () => {
      setIsBusy(true);

      api
        .put(`/orders/${_order.id}`, {
          delivery_method: _order.delivery_method,
          delivery_method_type: _order.delivery_method_type,
          delivery_method_location: _order.delivery_method_location,
          delivery_method_instructions: _order.delivery_method_instructions
        })
        .then((response) => {
          setMode('view');
          if (onBusy)
             onBusy(false);
          setIsBusy(false);
        })
        .catch((error) => {
          setIsBusy(false);
        });
        if(onHandleResult)
        onHandleResult(true);
    };

    const onEditButton = ()=>{
      if(onHandleResult)
      onHandleResult(false);
      setMode('edit');
      if (onBusy)
        onBusy(true);
    }

   
    useEffect(() => {      

      let delivery_method = order.delivery_method;
      let delivery_method_type = order.delivery_method_type;
      let delivery_method_location = order.delivery_method_location || '';
      setTableLocation(window['destination']);

      if (order.delivery_method !== null && ((order.vendor.delivery_method_types.some(v => v === order.delivery_method) || (order.vendor.delivery_method_types.some(v => v === order.delivery_method_type))))){
        setMode('view');
        if (onBusy)
           onBusy(false);
        if(onHandleResult)
           onHandleResult(true);
      }
      else if (order.vendor.delivery_method_types.length === 1 && order.vendor.delivery_method_types[0] === 'address' && order.delivery_method !== order.vendor.delivery_method_types[0]){
        delivery_method = 'deliver';
        delivery_method_type = order.vendor.delivery_method_types[0];
        if(onHandleResult)
           onHandleResult(false);
      }
      else if (order.vendor.delivery_method_types.length === 1 && order.vendor.delivery_method_types[0] === 'table' && order.delivery_method !== order.vendor.delivery_method_types[0]){
        delivery_method = 'deliver';
        delivery_method_type = order.vendor.delivery_method_types[0];
        delivery_method_location = _tableLocation;
        if(onHandleResult)
           onHandleResult(false);
      }
      else if (order.vendor.delivery_method_types.length === 1 && 
        order.vendor.delivery_method_types[0] === 'collect' && order.delivery_method !== order.vendor.delivery_method_types[0]){
        if(onHandleResult)
           onHandleResult(true);
      } 
      else {
        if(onHandleResult)
        onHandleResult(false);
      }
      if (!order.vendor.delivery_method_types.includes('collect') ) {
        if (order.delivery_method === 'collect'){
          delivery_method = 'deliver';
        }

        if (!delivery_method_type) {
          delivery_method_type = order.delivery_method_types[0];
        }
      }
      
      setOrder({
        ...order, 
        delivery_method: delivery_method,
        delivery_method_type: delivery_method_type,
        delivery_method_location: delivery_method_location,
        delivery_method_instructions: order.delivery_method_instructions || '',
      });
    }, [order]);
    
    useEffect(() => {
      

      let errors = null;
      if (_order.delivery_method === 'deliver') {
        if (_order.delivery_method_type === '') {
          errors = {};
          setMode('edit');
          if (onBusy)
             onBusy(true);
          errors.delivery_method_type = 'Please select on of the delivery method types';
        }

        if (!_order.delivery_method_location) {
          errors = {};
          setMode('edit');
          if (onBusy)
             onBusy(true);
          if(onHandleResult)          
             onHandleResult(false);
          errors.delivery_method_location = 'Please specify a delivery method location';
        }
      }
      
      setErrors(errors);
    }, [_order]);

    return (
        <>
          {
            mode === 'view' ?
              <div className="mt-2">
                <h3>
                  Collect / Deliver
                  {
                    (!disabled && //Dont show edit ic only collect
                     !(order.vendor.delivery_method_types.length === 1 && order.vendor.delivery_method_types[0] === 'collect'))                    
                    &&
                      <button type="button" style={{height:"50px", backgroundColor:"green", color:"white", borderStyle:"none" }} className='btn btn-lgd btn-dark float-end' onClick={() => onEditButton()} >Change</button>
                  }
                </h3>
                
                {
                  _order.delivery_method === 'collect' ? 
                    <>
                      <div className="form-label" >For collection from</div>
                      { _order.vendor.address_1 ? <div>{_order.vendor.address_1}</div> : null }
                      { _order.vendor.address_2 ? <div>{_order.vendor.address_2}</div> : null }
                      { _order.vendor.city ? <div>{_order.vendor.city}</div> : null }
                    </>
                  :
                    <>
                      <div >For delivery to {_order.delivery_method_type} #{_order.delivery_method_location}</div>
                      <div className='card-text'>{_order.delivery_method_instructions}</div> 
                    </>
                }

                {
                  _errors?.delivery_method_type || _errors?.delivery_method_location ?
                    <div className='alert alert-danger mt-3' >
                      {
                        _errors?.delivery_method_type &&
                        <div>{_errors.delivery_method_type}</div>
                      }

                      {
                        _errors?.delivery_method_location &&
                        <div>{_errors.delivery_method_location}</div>
                      }
                    </div>
                  : 
                    null
                }
                
              </div>
            :
              <>  
                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <label className="form-label" htmlFor="city5">Collect / Deliver</label>
                    <select className="form-control form-select" id="delivery_method" value={_order.delivery_method} onChange={(e) => handleOrderDeliveryMethodChange(order, e.target.value)}>
                      { _order.vendor.delivery_method_types.includes('collect') && <option value="collect" >Collect</option> }
                      { 
                        _order.vendor.delivery_method_types.includes('table') || 
                        _order.vendor.delivery_method_types.includes('room') || 
                        _order.vendor.delivery_method_types.includes('address') ?
                          <option value="deliver" >Deliver</option> 
                        :
                        null
                      }
                    </select>
                  </div>
                </div>

                {
                  _order.delivery_method === 'deliver' && 
                    <>
                      <div className="form-group boxed">
                        <div className="input-wrapper">
                          <label className="form-label" htmlFor="city5">Deliver to</label>
                          <select className="form-control form-select" id="delivery-method-type" value={_order.delivery_method_type} onChange={(e) => handleOrderDeliveryMethodTypeChange(order, e.target.value)}>
                            <option value="" >Select One</option>
                            { _order.vendor.delivery_method_types.indexOf('table') > -1 && <option value="table" >Table</option> }
                            { _order.vendor.delivery_method_types.indexOf('room') > -1 && <option value="room" >Room</option> }
                            { _order.vendor.delivery_method_types.indexOf('address') > -1 && <option value="address" >Address</option> }
                          </select>
                          {
                            _errors?.delivery_method_type && 
                              <div class="text-danger">{_errors?.delivery_method_type}</div>
                          }
                        </div>
                      </div>

                      { 
                        _order.delivery_method_type === 'table' &&
                          <div className="form-group boxed">
                            <div className="input-wrapper">
                              <label className="form-label" htmlFor={`${_order.id}-delivery-method-location`} >Table #</label>
                              <input type="text" className="form-control"  id={`${_order.id}-delivery-method-location`} value={_order.delivery_method_location} onChange={(e) => handleOrderDeliveryMethodLocationChange(order, e.target.value)} />  
                              {
                                _errors?.delivery_method_location && 
                                  <div class="text-danger">Table number is required.</div>
                              }
                            </div>
                          </div>
                      }

                      { 
                        _order.delivery_method_type === 'room' &&
                          <div className="form-group boxed">
                            <div className="input-wrapper">
                              <label className="form-label" htmlFor={`${_order.id}-delivery-method-location`} >Room #</label>
                              <input type="text" className="form-control" id={`${_order.id}-delivery-method-location`} value={_order.delivery_method_location} onChange={(e) => handleOrderDeliveryMethodLocationChange(order, e.target.value)} />  
                              {
                                `_errors`?.delivery_method_location && 
                                  <div class="text-danger">Room number is required.</div>
                              }
                            </div>
                          </div>
                      }

                      { 
                        _order.delivery_method_type === 'address' &&
                          <div className="form-group boxed">
                            <div className="input-wrapper">
                              <label className="form-label" htmlFor={`${_order.id}-delivery-method-location`} >Address</label>
                              <textarea
                                id={`${_order.id}-delivery-method-location`}
                                className="form-control"
                                rows="3"
                                onChange={(e) => handleOrderDeliveryMethodLocationChange(order, e.target.value)}
                                value={_order.delivery_method_location}
                              ></textarea>
                              {
                                _errors?.delivery_method_location && 
                                  <div class="text-danger">{_errors?.delivery_method_location}</div>
                              }
                            </div>
                          </div>
                      }

                      <div className="form-group boxed">
                        <div className="input-wrapper">
                          <label className="form-label" htmlFor={`${_order.id}-delivery-method-instructions`} >Instructions</label>
                          <textarea
                            className="form-control"
                            rows="3"
                            onChange={(e) => handleOrderDeliveryMethodInstructionsChange(order, e.target.value)}
                          >{_order.delivery_method_instructions}</textarea>
                        </div>
                      </div>
                    </>
                }
              
                {
                  _order.delivery_method === 'collect' &&
                    <div className="mt-2">
                      { _order.vendor.address_1 ? <div>{_order.vendor.address_1}</div> : null }
                      { _order.vendor.address_2 ? <div>{_order.vendor.address_2}</div> : null }
                      { _order.vendor.city ? <div>{_order.vendor.city}</div> : null }
                    </div>
                }

                <div className="mt-2">     
                
                  <button className="btn btn-sm" style={{height:"40px", width:"75%", backgroundColor:"green", color:"white"}} onClick={() => handleSubmit()} disabled={isBusy || _errors !== null}>Apply</button>
                  <button type="button" style={{height:"40px", width:"20%", backgroundColor:"white", color:"black", borderColor:"black", borderStyle:"solid", borderWidth:"1px"}} className="btn btn-sm btn-default float-end" onClick={() => handleCancel()}>Cancel</button>&nbsp;
                </div>
              </>
            }
          </>
    );
}

export default OrderCollectionDelivery;