import "./App.css";

import { Route, Routes, useLocation } from "react-router-dom";

import { Modal } from "react-bootstrap";
import CacheBuster from "react-cache-buster";
import VenuesPage from "./pages/VenuesPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import VendorsPage from "./pages/VendorsPage";
import VendorDetailsPage from "./pages/VendorDetailsPage";
import VendorItemsPage from "./pages/VendorItemsPage";
import VendorItemDetailsPage from "./pages/VendorItemDetailsPage";
import ItemDetailsPage from "./pages/ItemDetailsPage";
import ErrorPage from "./pages/ErrorPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import CartsPage from "./pages/CartsPage";
import CartDetailsPage from "./pages/CartDetailsPage";
import StitchReturnPage from "./pages/StitchReturnPage";
import QueuesPage from "./pages/QueuesPage";
import PrintInvoicePage from "./pages/print";
import OrderDetailPage from "./pages/OrderDetailPage";
import ProfilePage from "./pages/ProfilePage";
import { AuthGaurd } from "./gaurds/AuthGaurd";
import Toast from "./common/Toast";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowAuthModal } from "./redux/features/auth/authSlice";
import Auth from "./components/Auth";
import { useEffect, useState } from "react";
import { fetchActiveVenueAction } from "./redux/features/app/globalActions";
import { fetchActiveCartAction } from "./redux/features/cart/cartActions";
import { fetchActiveQueuesAction } from "./redux/features/queues/queuesActions";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import packageInfo from "../package.json";
import * as React from "react";

const App = () => {
  const dispatch = useDispatch();
  const { activeVenueId } = useSelector((state) => state.global);
  const { user, showAuthModal, context, token } = useSelector(
    (state) => state.auth
  );
  const handleClose = () => dispatch(toggleShowAuthModal());
  const location = useLocation();
  const [echo, setEcho] = useState();
  const isProduction = true;

  //const version = "1.0.0.0";

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location]);

  useEffect(() => {
    if (activeVenueId) {
      dispatch(fetchActiveVenueAction({ id: activeVenueId }));
    }
  }, [dispatch, activeVenueId]);

  useEffect(() => {
    if (activeVenueId && user) {
      console.log({
        activeVenueId: activeVenueId,
        user: user,
        context: context,
      });

      dispatch(
        fetchActiveCartAction({ user_id: user.id, venue_id: activeVenueId })
      );
      dispatch(fetchActiveQueuesAction(context));
    }
  }, [dispatch, activeVenueId, user, context]);

  useEffect(() => {
    window["appcallback"] = function () {
        dispatch(fetchActiveQueuesAction(context));
    };
    if (!echo && token) {
      window.Pusher = Pusher;
      const options = {
        broadcaster: "pusher",
        key: process.env.REACT_APP_PUSHER_APP_KEY,
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        forceTLS: process.env.REACT_APP_PUSHER_APP_SCHEME === "https",
        encrypted: false,
        authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        },
      };

      setEcho(new Echo(options));
    }
    if (echo) {
      if (user) {
        if (context.type === "Vendor") {
          echo
            .private(`vendors.${context.data.id}`)
            .listen("QueueOrderAdded", (order) => {
              // Refresh the queue
              dispatch(fetchActiveQueuesAction(context));

              // Send push notification if supported and permission has been granted.
              if ("Notification" in window) {
                if (Notification.permission === "granted") {
                  console.log("Echo is granted");
                  navigator.serviceWorker.getRegistration().then((reg) => {
                    console.log("Sending notification via service worker!");
                    console.log(order, "order");

                    var options = {
                      // body: 'Your order is ready to collect!',
                      icon: "/logo192.png",
                      vibrate: [100, 50, 100],
                      image: order.vendor.image ?? order.vendor.image,
                    };
                    reg.showNotification(
                      `A new order has been added to your queue.`,
                      options
                    );
                  });
                }
              }
            });
        } else {
          echo
            .private(`users.${user.id}`)
            .listen("QueueOrderStateUpdated", (order) => {
              console.log(order, "order");

              // Refresh the queue
              dispatch(fetchActiveQueuesAction(context));

              // Send push notification if supported and permission has been granted.
              if ("Notification" in window) {
                if (
                  Notification.permission === "granted" &&
                  order?.queue?.state === "done"
                ) {
                  navigator.serviceWorker.getRegistration().then((reg) => {
                    console.log("Sending notification via service worker!");

                    var options = {
                      body:
                        order.delivery_method === "deliver"
                          ? "Your order will be delivered shortly!"
                          : "You are welcome to collect!",
                      icon: "/logo192.png",
                      vibrate: [100, 50, 100],
                      image: order.vendor.image ?? order.vendor.image,
                    };

                    if (order.delivery_method === "deliver") {
                      reg.showNotification(
                        `Your ${order.vendor.name} is done!`,
                        options
                      );
                    } else {
                      reg.showNotification(
                        `Your ${order.vendor.name} is done!`,
                        options
                      );
                    }
                  });
                }
              }
            });
        }
      } else {
        echo.leaveAllChannels();
      }
    }
  }, [user, context, token, echo, dispatch]);

  return (
    <div>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      ></CacheBuster>
      <Routes>
        <Route path="/" element={<VenuesPage />} />

        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/password-reset/:token" element={<PasswordResetPage />} />

        <Route path="/venues" element={<VenuesPage />} />
        <Route path="/venues/:venueId">
          <Route path="/venues/:venueId/vendors" element={<VendorsPage />} />
          <Route
            path="/venues/:venueId/vendors/location/:location"
            element={<VendorsPage />}
          />
          <Route
            path="/venues/:venueId/vendors/:vendorId"
            element={<VendorDetailsPage />}
          />

          <Route
            path="/venues/:venueId/vendors/:vendorId/items/:itemId"
            element={<ItemDetailsPage />}
          />
        </Route>
        <Route path="/venuesa/:venueId">
          <Route path="/venuesa/:venueId/vendors" element={<VendorsPage />} />
          <Route
            path="/venuesa/:venueId/vendors/location/:location"
            element={<VendorsPage />}
          />
          <Route
            path="/venuesa/:venueId/vendors/:vendorId"
            element={<VendorDetailsPage />}
          />

          <Route
            path="/venuesa/:venueId/vendors/:vendorId/items/:itemId"
            element={<ItemDetailsPage />}
          />
        </Route>

        <Route element={<AuthGaurd user={user} />}>
          <Route path="/queues" element={<QueuesPage />} />

          <Route path="/venues/:venueId">
            <Route path="/venues/:venueId/carts" element={<CartsPage />} />
            <Route path="/venues/:venueId/queues" element={<QueuesPage />} />

            <Route path="/venues/:venueId/cart" element={<CartDetailsPage />} />
            <Route
              path="/venues/:venueId/cart/:location"
              element={<CartDetailsPage />}
            />
            <Route path="/venues/:venueId/profile" element={<ProfilePage />} />
          </Route>
          <Route path="/order" element={<OrderDetailPage />} />
          <Route path="/order/print" element={<PrintInvoicePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/your-items/:vendorId" element={<VendorItemsPage />} />
          <Route
            path="/vendor/:vendorId/items/:itemId"
            element={<VendorItemDetailsPage />}
          />
          <Route path="/stitch/return" element={<StitchReturnPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>

      <Toast></Toast>

      <Modal
        className="modal fade modalbox"
        show={showAuthModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Auth></Auth>
        </Modal.Body>
        <Modal.Footer>&nbsp;</Modal.Footer>
      </Modal>
    </div>
  );
};

export default App;
