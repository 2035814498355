import { createSlice } from "@reduxjs/toolkit";
import { fetchActiveQueuesAction } from "./queuesActions";

const queue = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : [];

const initialState = {
    loading: false,
    data: queue,
    error: null
}

export const queuesSlice = createSlice({
    name: queue,
    initialState,
    reducers: {
        logOutQueue: (state, action) => {
            state.data =[];
            localStorage.removeItem('queue');
        },
        updateQueueItemState: (state, action) => {
            state.data = [
                ...state.data.map(
                    (queueItem) => { return queueItem.id === action.payload.id ? {...queueItem, state: action.payload.state} : queueItem }
                )
            ];
            
        },
    },
    extraReducers: {
        [fetchActiveQueuesAction.pending]: (state) => {
            state.loading = true;
        },
        [fetchActiveQueuesAction.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload
        },
        [fetchActiveQueuesAction.rejected]: (state, action) => {
            state.loading = false;
            state.data = []
        }
    }
});


export const selectQueueCount = (state) => {
    return 2
}

export const { updateQueueItemState, logOutQueue } = queuesSlice.actions

export default queuesSlice.reducer;