import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BottomMenu from "../common/BottomMenu";
import Footer from "../common/Footer";
import SidebarPanel from "../common/SidebarPanel";
import NotificationsBar from "../common/NotificationsBar";
import api from "../services/api.service";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../common/AppHeader";
import NoRecordsFound from "../common/NoRecords";
import Loader from "../common/Loader";
import { setActiveVenueId } from "../redux/features/app/globalSlice";

const VendorsPage = () => {
  const { venueId } = useParams();
  const [venue, setVenue] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { location } = useParams();

  const { user } = useSelector((state) => state.auth);

  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    if ((window['destination'] == null) && (window['destination'] !== location && location != null)){
       window['destination'] = location;
    }

    if (!venueId) {
      navigate("/venues");
      return;
    }



    api
      .get("/vendors", {
        params: {
          venue_id: venueId,
        },
      })
      .then((response) => {
        setVendors(response.data.data);
        api
          .get(`/venues/${venueId}`)
          .then((response) => {
            setVenue(response.data.data);
          }).then(()=>
          {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user, venueId, navigate]);

  useEffect(() => {
    dispatch(setActiveVenueId(venueId));
  }, [dispatch, venueId]);

  return (
    <Fragment>
       
      <AppHeader name={venue !== null ? venue.name : "Market"}></AppHeader>
      {isLoading ? (
        <div id="appCapsule">
          <Loader />
        </div>
      ) : (
        
        <div id="appCapsule">
          <div className="header-block">
            <div
              className="header-inner"
              style={{
                backgroundImage:
                  venue && venue.image_url ? `url(${venue.image_url})` : "none",
              }}
            ></div>
         
          </div>
          <div className="header-text text-center">
              <h1>{venue && venue.name ? venue.name : "Market"}</h1>
              <div className="header-description text-center">
                <p>{venue && venue.blurb ? venue.blurb : "Market"}</p>
                <hr />
              </div>
            </div>
          <div className="markets-body alt-bg ">
            {vendors.length > 0 ? (
              vendors.map((vendor, index) => {
                return (
                  <div className="block-card content-block  my-3" key={vendor.id || index}>
                    <Link
                      to={`/venues/${vendor.venue_id}/vendors/${vendor.id}`}
                    >
                      <div className="">
                        <div
                          className="block-inner "
                          style={{
                            backgroundImage:
                              vendor && vendor.image_url
                                ? `url(${vendor.image_url})`
                                : "none",
                          }}
                        ></div>
                        <div className="block-text text-center">
                          <h3>{vendor.name}</h3>
                          <hr />
                          <p>{vendor.blurb}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <NoRecordsFound />
            )}
          </div>
          <Footer />
        </div>
      )}

      <BottomMenu />
      <NotificationsBar />
      <SidebarPanel />
    </Fragment>
  );
};

export default VendorsPage;
