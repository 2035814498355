import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link as ScrollLink } from "react-scroll";
import BottomMenu from "../common/BottomMenu";
import SidebarPanel from "../common/SidebarPanel";
import AppHeader from "../common/AppHeader";
import api from "../services/api.service";
import Loader from "../common/Loader";
import { setActiveVenueId } from "../redux/features/app/globalSlice";
import Footer from "../common/Footer";
import NotificationsBar from "../common/NotificationsBar";
function ScrollItem({ id, children, onInView }) {
  const [isInView, setIsInView] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(id);
      if (!element) return;
      const rect = element.getBoundingClientRect();
      const inView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= window.innerHeight &&
        rect.right <= window.innerWidth;
      setIsInView(inView);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [id]);

  useEffect(() => {
    if (onInView) {
      onInView(id, isInView);
    }
  }, [onInView, isInView, id]);

  return <div id={id}>{children}</div>;
}

const VendorDetailsPage = () => {
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { venueId, vendorId } = useParams();
  const [visibleSection, setVisibleSection] = useState();
  const [vendor, setVendor] = useState(null);
  const [venue, setVenue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const offsetPixels = -200;
  const { user } = useSelector((state) => state.auth);
  const [isSticky, setIsSticky] = useState(false);
  const navBarRef = useRef(null);
  const menuIdxRef = useRef(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const firstNavItem = navBarRef.current?.querySelector(
    ".nav-link:first-child"
  );
  const firstNavItemWidth = firstNavItem ? firstNavItem.offsetWidth : 0;
  const scrollOffsetThreshold = firstNavItemWidth + 200 || 0;


  const checkStickiness = () => {
    const vh = window.innerHeight;
    const threshold = (vh * 10) / 100;
    setIsSticky(window.pageYOffset > threshold);
  };


  useEffect(() => {
    window.addEventListener("scroll", checkStickiness);

    return () => {
      window.removeEventListener("scroll", checkStickiness);
    };
  }, []);


  useEffect(() => {
    if (searchParams.get("delivery_method")) {
      localStorage.setItem(`vendors.${vendorId}.delivery_method`, searchParams.get("delivery_method"));
      localStorage.setItem(`vendors.${vendorId}.delivery_method_type`, searchParams.get("delivery_method_type"));
      localStorage.setItem(`vendors.${vendorId}.delivery_method_location`, searchParams.get("delivery_method_location"));
    }
  }, [searchParams, vendorId]);


  const handleNavLinkClick = (event) => {
    event.preventDefault();
    setIsButtonClicked(true);
    const item = event.currentTarget;
    scrollContainerRef.current.scrollLeft =
      item.offsetLeft - (window.innerWidth / 2 - item.offsetWidth / 2);
    const activeNavItems = document.querySelectorAll(
      ".vendor-nav .nav-link.active"
    );
    const scrollOffset =
      item.offsetLeft -
      (window.innerWidth / 2 - item.offsetWidth / 2) +
      window.innerHeight * 0.5;
    scrollContainerRef.current.scrollTo({
      left: scrollOffset,
      behavior: "auto",
    });
    activeNavItems.forEach((navItem) => {
      navItem.classList.remove("active");
    });
    item.classList.add("active");

    // Scroll the active nav item into view with smooth behavior
    item.scrollIntoView({
      block: "start", // Scroll to the top of the element
    });
    const handleScrollComplete = () => {
      setTimeout(() => {
        setIsButtonClicked(false);
      }, 500);
      window.removeEventListener("scroll", handleScrollComplete);
    };

    window.addEventListener("scroll", handleScrollComplete);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollItems = document.querySelectorAll(".scroll-item");
      let currentVisibleId = null;

      scrollItems.forEach((item) => {
        const { top } = item.getBoundingClientRect();
        const id = item.getAttribute("data-scroll");

        if (top >= 0 && top < window.innerHeight) {
          currentVisibleId = id;
        }
      });

      if (visibleSection !== currentVisibleId) {
        setVisibleSection(currentVisibleId);

        const activeNavItem = document.querySelector(
          `[data-scroll='${currentVisibleId}']`
        );
        const navItem = navBarRef.current?.querySelector(
          `[data-scroll='${currentVisibleId}']`
        );

        if (activeNavItem && navItem) {
          const containerLeft =
            scrollContainerRef.current?.getBoundingClientRect().left;
          const navItemLeft = navItem.getBoundingClientRect().left;
          const offset = navItemLeft - containerLeft;

          scrollContainerRef.current.scrollLeft += offset;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const menuItem = scrollContainerRef.current.querySelector(
        `[data-scroll='#${visibleSection}']`
      );

      if (menuItem) {
        const left = menuItem.offsetLeft;
        const width = menuItem.offsetWidth;
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const offset = left - (containerWidth - width);

        scrollContainerRef.current.scrollLeft = offset >= 0 ? offset : 0;
      }
    }
  }, [visibleSection]);

  useEffect(() => {
    setIsLoading(true);
   
    api
    .get(`/venues/${venueId}`)
    .then((response) => {
      setVenue(response.data.data);
    }).then(()=>
    {
      
    })
    .catch((error) => {
      console.error(error);
    });
    
    api
      .get("/vendors/" + vendorId)
      .then((response) => {
        setVendor(response.data.data);

        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [navigate, user, vendorId]);

  useEffect(() => {
    dispatch(setActiveVenueId(venueId));
  }, [dispatch, venueId]);







  // If vendor data hasn't been fetched yet, don't render the component.
  if (!vendor) {
    return null;
  }

  // const menuIds = vendor.menus
  //   ? vendor.menus.data.map((menu, idx) => `menu-${idx}`)
  //   : [];


  return (
    <Fragment>
      <AppHeader name={vendor.name} />
      {isLoading ? (
        <div id="appCapsule">
          <Loader />
        </div>
      ) : (
        <div id="appCapsule ">
          <div className="header-block">
            <div
              className="header-inner "
              style={{
                backgroundImage:
                  vendor && vendor.image_url
                    ? `url(${vendor.image_url})`
                    : "none",
              }}
            ></div>
          </div>
          <div className="header-text text-center">
            <h2>{vendor && vendor.name ? vendor.name : "Market"}</h2>
            <div className="header-description text-center">
              <p>{vendor && vendor.blurb ? vendor.blurb : "Market"}</p>
            </div>
          </div>
          <div
            ref={scrollContainerRef}
            className={`${isSticky
                ? "nav horizontal-scroll sticky-nav"
                : "nav horizontal-scroll"
              } `}
          >
            <ul
              ref={navBarRef}
              className={`nav w100 pb-md-3 horizontal-scroll vendor-nav pb-2 ${isSticky ? "sticky-nav  " : ""
                }`}
            >
              {vendor.menus.data.map((menu, menuIdx) => (
                <li
                  ref={menuIdxRef}
                  className={`nav-item px-1 mt-2 ${visibleSection === `menu-${menuIdx}` ? "active" : ""
                    }`}
                  key={`vendor.${vendor.id}.menu-${menu.id}.nav-item`}
                >
                  <ScrollLink
                    className="nav-link shadow-sm scroll-item"
                    to={`menu-${menuIdx}`}
                    spy={true}
                    offset={offsetPixels}
                    data-scroll={`#menu-${menuIdx}`}
                    onClick={(event) => handleNavLinkClick(event, menuIdx)}
                  >
                    {menu.name}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </div>

          <div className="tab-content menu-tabs">
            {vendor.menus.data.map((menu, menuIdx) => (
              <ScrollItem
                id={`menu-${menuIdx}`}
                key={`vendor.${vendor.id}.menu-${menu.id}.tab-content`}
                onInView={(id, isInView) => {
                  if (isInView && !isButtonClicked) {
                    setVisibleSection(id);
                    const navItem = navBarRef.current.querySelector(
                      `[data-scroll="#${id}"]`
                    );

                    if (navItem) {
                      let scrollOffset2 =
                        navItem.offsetLeft - navBarRef.current.offsetWidth / 2;

                      if (scrollOffset2 < scrollOffsetThreshold) {
                        scrollOffset2 = 0;
                      }

                      navBarRef.current.scrollLeft = scrollOffset2;
                    }
                  }
                }}
              >
                <div
                  className={`tab-pane fade show active `}
                  id={`menu-${menuIdx}`}
                  key={`vendor.${vendor.id}.menu-${menu.id}.tab-content`}
                  role="tabpanel"
                >
                  {menu.groups &&
                    menu.groups.data &&
                    menu.groups.data.length > 0 &&
                    menu.groups.data.map((group) => (
                      <Fragment
                        key={`vendors.${vendor.id}.menus.${menu.id}.groups.${group.id}`}
                      >
                        {group.items.data.length > 0 && (
                          <h2 className="my-2 p-2 main-color fw-normal alt-bg menu-group-title" id={group.id}>
                            {group.name}
                          </h2>
                        )}
                        {group.items &&
                          group.items.data &&
                          group.items.data.length > 0 && (
                            <div className="container-inline border-top-0 border-bottom-0 vendor row p-0 m-0 " data-id={group.id}
                              style={{ position: "relative" }}
                            >
                              {
                                group.items.data.filter(item => item.status === 'active').map((item) => (
                                  <div
                                    key={`vendor.${vendor.id}.menu.${menu.id}.group.${group.id}.item.${item.id}`}
                                    className="vendor-item content-block col-4 col-md-3 col-lg-1 pt-1 text-center"
                                    style={{ position: "relative", overflow: "hidden", paddingBottom: "25px" }}
                                  >
                                    <Link
                                      to={`/venues/${vendor.venue_id}/vendors/${vendorId}/items/${item.id}`}
                                      className="item"
                                    >
                                      {item.image_url ? (
                                        <img
                                          className="vendor-item-img rounded-3"
                                          src={item.image_url}
                                          alt="product"
                                          style={{ alignSelf: "start" }}
                                        />
                                      ) : (
                                        <img
                                          className="no-img-product  rounded-3 p-2"
                                          style={{
                                            alignSelf: "start",
                                            backgroundColor: '#e0e0e0'
                                          }}
                                          src="/../assets/img/no-img-product.png"
                                          alt="product"

                                        />
                                      )}
                                      <div className="" style={{
                                        alignSelf: "start",
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        paddingTop: "10px",
                                        marginLeft: "3px",
                                        marginRight: "3px",
                                        position: "relative"
                                      }}>
                                        <div className="pt1-1" style={{ position: "relative" }}>
                                          <div
                                            className="pb-1 menuItem"
                                            style={{ width: "100%", marginRight: "20px", textAlign: "left", paddingLeft: "5px", paddingRight: "5px" }}
                                          >
                                            <p style={{ wordWrap: "normal" }}>{item.name}</p>
                                          </div>

                                          {/* <div
                                            className="fw-normal ts-normal"
                                            style={{ fontSize: "15px" }}
                                          >
                                            <p>{item.description}</p>
                                          </div> */}
                                        </div>
                                        {/* <div className=" fs-5 text-white main-bg-color desktop-icon">
                                          <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </div> */}
                                        <div className="d-none">{group.name}</div>
                                      </div>
                                    </Link>
                                    <div className="text-success fw-bold pb-1 bottom-content"
                                      style={{ fontSize: "14px", position: "absolute", display: "table-cell", verticalAlign: "bottom", bottom: "0" }}
                                    >
                                      <b>{venue?.currency_symbol} {item.master.price}</b>
                                    </div>
                                  </div>
                                ))}

                            </div>
                          )}
                      </Fragment>
                    ))}
                </div>
              </ScrollItem>
            ))}
            <div className="bottom-menu"></div>
          </div>

          <Footer />
        </div>
      )}


      <NotificationsBar />
      <BottomMenu />
      <SidebarPanel />
    </Fragment>
  );
};

export default VendorDetailsPage;
