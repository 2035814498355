import React, { useState, useEffect } from "react";
import api from "../services/api.service";
import { utils, writeFile } from 'xlsx';
import {  useSelector } from "react-redux";

const ExportButton = ({ date }) => {
    const [orders, setOrders] = useState([]);
    const { context } = useSelector((state) => state.auth);
    
    useEffect(() => {
        if (context.type === 'Vendor') {
            api
                .get("/vendors/" + context.data.id + '/orders', { })
                .then((response) => {
                    setOrders(response.data.data);
                });
        }
    }, [context]);
    
    const generateXLSX = () => {
        const wsData = [
            ['Vendor Name' , 'Order Number', 'Date Order Placed', 'Time Order Placed', 'Item', 'Item Price', 'ITEM QTY', 'Variant', 'Variant Price', 'Variant QTY', 'Options', 'Option Price', 'Option QTY', 'Service Charge', 'Grand Total', 'Delivery Method']
        ];
    
        if (!orders || !Array.isArray(orders)) {
            return wsData;
        }

        console.log(orders)
        orders.forEach(order => {
         
            order.order_items.data.forEach(item => {
                if(context.data.id === order.vendor.id) {
                const orderDate = new Date(order.created_at);
                const orderDateFormatted = orderDate.toLocaleDateString('en-ZA')
                const orderTimeFormatted = orderDate.toLocaleTimeString('en-ZA', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false 
                  });

                if(orderDate.getTime() / 1000 >= new Date(date).getTime() / 1000) {
                if (item.item_options.data.length === 0) {
                    const row = [
                        order.vendor?.name || 'N/A',
                        order.order_number || 'N/A',
                        orderDateFormatted,
                        orderTimeFormatted,
                        item.variant.item?.name || 'N/A',
                        item.variant.item?.master?.price || 0,
                        item.quantity || 0,
                        item.variant.name || 'N/A',
                        item.variant.price || 0,
                        item.quantity || 0,
                        'N/A', 
                        0,      
                        0,      
                        0,      
                        order.total || 0,
                        order.delivery_method || 'N/A'
                    ];
                    wsData.push(row);
                } else {
                    item.item_options.data.forEach(option => {
                        const row = [
                            order.vendor?.name || 'N/A',
                            order.order_number || 'N/A',
                            orderDateFormatted,
                            orderTimeFormatted,
                            item.variant.item?.name || 'N/A',
                            item.variant.item?.master?.price || 0,
                            item.quantity || 0,
                            item.variant.name || 'N/A',
                            item.variant.price || 0,
                            item.quantity || 0,
                            option.option?.name || 'N/A',
                            option.option?.price || 0,
                            1, 
                            0,  
                            order.total || 0,
                            order.delivery_method || 'N/A'
                        ];
                        wsData.push(row);
                    });
                }
            }
            }
            });
        
        });
        
    
        return wsData;
    };
    
    const downloadXLSX = () => {
        const wsData = generateXLSX();
        if (wsData.length>1){
        const ws = utils.aoa_to_sheet(wsData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
    
        writeFile(wb, 'export.xlsx');
        }
        else {
            alert('No orders to export')
        }
    };

    return <button className="btn btn-dark text-white w-100 w-md-50 rounded-full mb-2" onClick={downloadXLSX}>Export Orders</button>;
};

export default ExportButton;
