import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logOut } from "../redux/features/auth/authSlice";
//import { logOutQueue } from "../redux/features/queues/queuesSlice";


const SidebarPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { venueId } = useParams();
  const { user } = useSelector((state) => state.auth);
  
  const { serviceWorkerRegistrationUpdated, serviceWorkerRegistration } = useSelector((state) => state.global);

  const handleLogOut = (e) => {
    // e.preventDefault();
    dispatch(logOut());
    //dispatch(logOutQueue());
    navigate("/");
  };

  const updateServiceWorkerRegistration = (e) => {
    const registrationWaiting = serviceWorkerRegistration.waiting;  
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });    
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  return (
    <div
      className="offcanvas offcanvas-start radius-bottom-right"
      tabIndex="-1"
      id="sidebarPanel"
    >
      <div className="offcanvas-body position-relative ">
        {user && (
          <div className="profileBox main-bg-color text-center position-relative radius-top-right ">
            <div className="in">
              <img
                className="img-fluid p-1"
                src="/assets/img/white-logo.png"
                alt="logo"
              />
              <strong>Hi, {user?.name}</strong>
            </div>
            <a
              href="!#"
              className="close-sidebar-button"
              data-bs-dismiss="offcanvas"
            >
              <ion-icon name="close"></ion-icon>
            </a>
          </div>
        )}
        {!user && (
          <div className="profileBox main-bg-color text-center position-relative radius-bottom-right ">
            <div className="in">
              <img
                className="img-fluid p-1"
                src="/assets/img/white-logo.png"
                alt="logo"
              />
            </div>
            <a
              href="!#"
              className="close-sidebar-button"
              data-bs-dismiss="offcanvas"
            >
              <ion-icon name="close"></ion-icon>
            </a>
          </div>
        )}

        <ul className="listview flush transparent no-line image-listview mt-2">
          {
            serviceWorkerRegistrationUpdated && 
              <li>
                <a
                  href="!#"
                  className="item"
                  onClick={(e) => updateServiceWorkerRegistration()}
                  data-bs-dismiss="offcanvas"
                >
                  <div className="in ">
                    <b>
                      <i class="bi bi-download"></i> Update Available
                    </b>
                  </div>
                </a>
                <hr />
              </li>
          }

          <li>
            <a
              href="!#"
              className="item"
              onClick={(e) => navigate("/venues")}
              data-bs-dismiss="offcanvas"
            >
              <div className="in ">
                <b>
                  <ion-icon name={`Location`}></ion-icon> Venues
                </b>
              </div>
            </a>
            <hr />
          </li>
          {venueId && (
            <li>
              <a
                href="!#"
                className="item"
                onClick={(e) => navigate(`/venues/${venueId}/vendors`)}
                data-bs-dismiss="offcanvas"
              >
                <div className="in ">
                  <b>
                    <ion-icon name={`fast-food`}></ion-icon> Vendors
                  </b>
                </div>
              </a>
              <hr />
            </li>
          )}

          {user && (
            <li>
              <a
                href="!#"
                className="item"
                onClick={(e) => navigate("/profile")}
                data-bs-dismiss="offcanvas"
              >
                <b>
                  <ion-icon name="person-circle"></ion-icon> My Account
                </b>
              </a>
              <hr />
            </li>
          )}
        </ul>
        <div className="p-3 position-absolute top pb-5 mb-2 text-center">
          <p>
            Experience the future of food ordering with Kwika. No app download
            required. Effortlessly search, order, and pay for meals online. It's
            hassle-free.
          </p>
          <p>
            Telephone : +27-87-700-0452 <br />
            <a
              className="text-black"
              href="https://www.kwikQ.co.za"
              rel="_blank"
            >
              www.kwikQ.co.za
            </a>
          </p>
        </div>
      </div>
      {user && (
        <div className="sidebar-buttons p-1 d-flex justify-content-around radius-bottom-right text-center text-white">
          <a
            href="!#"
            className="btn rounded-full btn-primary w-45"
            onClick={(e) => handleLogOut(e)}
            data-bs-dismiss="offcanvas"
          >
            Log Out
          </a>
          <a
            href="https://kwika.ca/support/"
            target="_blank"
            rel="noreferrer"
            className="btn rounded-full btn-primary w-45"
          
          >
            Get Support
          </a>
        </div>
      )}

      {!user && (
        <div className="sidebar-buttons p-1 d-flex justify-content-around radius-bottom-right text-center text-white">
          <a
            href="!#"
            className="btn rounded-full btn-primary w-45"
            onClick={(e) => navigate("/Login")}
            data-bs-dismiss="offcanvas"
          >
            Log In
          </a>
          |
          <a
            href="!#"
            className="btn rounded-full btn-primary w-45"
            onClick={(e) => navigate("/register")}
            data-bs-dismiss="offcanvas"
          >
            Register
          </a>
        </div>
      )}
    </div>
  );
};

export default SidebarPanel;
