import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../PrintInvoicePage.css";
import api from "../services/api.service";
import Loader from "../common/Loader";

const PrintInvoicePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { user, context } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState([]);
  const showHST = process.env.HASHST;
  const showAdjustment = true;
  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  var AdjustmentTotal = (props) => {
    let invoiceData = props.invoiceData.cart;
    let hasTax =
      props.invoiceData.tax_name !== null && props.invoiceData.tax_name !== "";
    let includes =
      props.invoiceData.tax_inclusive === "Includes" ? "Includes " : "";
    if (!hasTax) {
      props.invoiceData.tax_name = "Tax";
    }
    return (
      <>
        <div>
          <p className="totaladjustment" style={{ padding: "0", margin: "0" }}>
            Service fee :{" "}
            <span className="text-success">
              {" "}
              {props.invoiceData.currency_symbol}{" "}
              {(+props.invoiceData.service_fee).toFixed(2)}{" "}
            </span>
          </p>
        </div>
        {includes ? (
          <div>
            <p
              className="totaladjustment"
              style={{ padding: "0", margin: "0" }}
            >
              {" "}
              {includes} {props.invoiceData.service_fee_tax_name} on Service fee
              :{" "}
              <span className="text-success">
                {" "}
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.service_fee_tax).toFixed(2)}{" "}
              </span>
            </p>
          </div>
        ) : (
          <div>
            <p
              className="totaladjustment"
              style={{ padding: "0", margin: "0" }}
            >
              {" "}
              {props.invoiceData.service_fee_tax_name}: (Reference:{" "}
              {props.invoiceData.service_fee_tax_number}) on Service fee :{" "}
              <span className="text-success">
                {" "}
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.service_fee_tax).toFixed(2)}{" "}
              </span>
            </p>
          </div>
        )}
      </>
    );
  };

  var AdjustmentTotalIncludes = (props) => {
    let invoiceData = props.invoiceData.cart;
    let hasTax =
      props.invoiceData.tax_name !== null && props.invoiceData.tax_name !== "";
    let includes =
      props.invoiceData.tax_inclusive === "Includes" ? "Includes " : "";
    if (!hasTax) {
      props.invoiceData.tax_name = "Tax";
    }
    return (
      <>
      <div>
          <div className="w-100 py-0 px-0 vatfooterheader ">
            <div className="w-100 py-0 px-0 vatfooter">
              <div className="totals-row">
                <div>
                  KwikQ Service Fee : 
                </div>{" "}
                <div>
                <span className="text-success singleUnderline">
                  {" "}
                  {props.invoiceData.currency_symbol}{" "}
                  {(+props.invoiceData.service_fee).toFixed(2)}{" "}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  var IncludesFooter = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <div>
        <div className="w-100 py-3 px-0 vatfooterheader ">
          <p>VAT PAYMENTS</p>
          <div className="w-100 py-3 px-0 vatfooter">
            <div className="totals-row">
              <div>
                {invoiceData.tax_name} on product sales :{" "}
              </div>{" "}
              <div className="text-success">
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.tax_amount).toFixed(2)}
                <br />
              </div>
            </div>
            <div className="totals-row">
              <div>
                {invoiceData.tax_name} on service fee :{" "}
              </div>
              <div className="text-success">
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.service_fee_tax).toFixed(2)}
                <br />
              </div>
            </div>
            <div className="totals-row">
              <div>
                {invoiceData.tax_name} Total :{" "}
              </div>
              <div className="text-success">
                {props.invoiceData.currency_symbol}{" "}
                {(
                  +props.invoiceData.service_fee_tax +
                  +props.invoiceData.tax_amount
                ).toFixed(2)}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  var HstTotal = (props) => {
    let invoiceData = props.invoiceData;
    let includes =
      props.invoiceData.tax_inclusive === "Includes" ? "Includes " : "";
    return (
      <>
        <p className="fw-bold text-end">
          {includes}
          {invoiceData.tax_name} :{" "}
          {/* <span className="text-success">$ {parseFloat((invoiceData.total + 1) * .13).toFixed(2)}</span> */}
          {/* <span className="text-success">$ {parseFloat((+invoiceData.total + +invoiceData.cart.adjustment_total) * .13).toFixed(2)}</span> */}
          <span className="text-success">
            {props.invoiceData.currency_symbol}{" "}
            {parseFloat(+invoiceData.tax_amount).toFixed(2)}
          </span>
        </p>
      </>
    );
  };

  var OrderTotal = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <>
        <p className="fw-bold text-end pt-1">
          Order Total:{" "}
          {/* <span className="text-success">$ {parseFloat((invoiceData.total + 1) * 1.13).toFixed(2)}</span> */}
          {/* //Excludes*/}
          {/* <span className="text-success">$ {parseFloat((+invoiceData.total + +invoiceData.cart.adjustment_total) * 1.13).toFixed(2)}</span> */}
          {/* //Includes */}
          {invoiceData.tax_inclusive === "Includes" ? (
            <span className="text-success">
              {props.invoiceData.currency_symbol}{" "}
              {parseFloat(
                +invoiceData.total + +invoiceData.service_fee
              ).toFixed(2)}
            </span>
          ) : (
            <span className="text-success">
              {props.invoiceData.currency_symbol}{" "}
              {parseFloat(
                +invoiceData.total +
                  +invoiceData.service_fee +
                  +invoiceData.tax_amount +
                  +invoiceData.service_fee_tax
              ).toFixed(2)}
            </span>
          )}
        </p>
      </>
    );
  };

  var OrderTotalIncludes = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <>
        <div>
          <div className="w-100 py-0 px-0 vatfooterheader ">
            <div className="w-100 py-0 px-0 vatfooter">
              <div className="totals-row">
                <div>
                  Total :
                </div>{" "}
                <div>
                  {invoiceData.tax_inclusive === "Includes" ? (
                    <span className="text-success doubleUnderline">
                      {props.invoiceData.currency_symbol}{" "}
                      {parseFloat(
                        +invoiceData.total + +invoiceData.service_fee
                      ).toFixed(2)}
                    </span>
                  ) : (
                    <span className="text-success doubleUnderline">
                      {props.invoiceData.currency_symbol}{" "}
                      {parseFloat(
                        +invoiceData.total +
                          +invoiceData.service_fee +
                          +invoiceData.tax_amount +
                          +invoiceData.service_fee_tax
                      ).toFixed(2)}
                    </span>
                  )}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  var ProductTotalIncludes = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <>
         <div>
          <div className="w-100 py-0 px-0 vatfooterheader ">
            <div className="w-100 py-0 px-0 vatfooter">
              <div className="totals-row">
                <div>
                  Product Total :
                </div>{" "}
                <div>
                  {invoiceData.tax_inclusive === "Includes" ? (
                    <span className="text-success">
                      {props.invoiceData.currency_symbol}{" "}
                      {parseFloat(
                        +invoiceData.total
                      ).toFixed(2)}
                    </span>
                  ) : (
                    <span className="text-success">
                      {props.invoiceData.currency_symbol}{" "}
                      {parseFloat(
                        +invoiceData.total 
                      ).toFixed(2)}
                    </span>
                  )}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  var NoHstTotal = (props) => {
    let invoiceData = props.invoiceData;

    return (
      <>
        {/* <p className="fw-bold text-end pt-1">
        Service Fee:{" "}
        <span className="text-success">$ {parseFloat((+1)).toFixed(2)}</span>
      </p> */}
        <p className="fw-bold text-end pt-1">
          Order Total:{" "}
          <span className="text-success">
            {props.invoiceData.currency_symbol}{" "}
            {parseFloat(
              +invoiceData.total +
                +invoiceData.service_fee +
                +invoiceData.service_fee_tax
            ).toFixed(2)}
          </span>
        </p>
      </>
    );
  };

  const handlePrint = useCallback((invoiceData) => {
    return new Promise((resolve) => {
      document.title = `receipt: #${invoiceData.order_number} vendor: ${invoiceData.vendor.name} for ${invoiceData.user.name}`;

      if (isMobile()) {
        window.print();
      } else {
        try {
          document.execCommand('print', false, null);
        } catch (error) {
          window.print();
        }
      }

      setTimeout(() => {
        resolve();
        navigate(-1);
      }, 1000);
    });
  }, [navigate]);

  useEffect(() => {
    api
      .get(`/orders/${queryParams.get("id")}`)
      .then((response) => {
        setInvoiceData(response.data.data);
        setIsLoading(false);
        setTimeout(() => {
          return handlePrint(response.data.data);
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user, navigate, context, dispatch, setIsLoading, queryParams, handlePrint]);





  return (
    <Fragment>
      <div id="appCapsule">
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>

            <div className="invoice-wrapper">

              <div className="invoice">
                <div className="invoice-header">
                  <div className="w-50 text-start d-flex align-items-center">
                    <img src="/assets/img/logo.png" alt="Logo" className="logo" width="150px" height="auto" />
                    <h3 className='pt-1'>KwikQ</h3>
                  </div>
                  <div className='text-end fw-bold'>
                    <p>Order Number: {invoiceData.order_number}<br />
                      Order Date: {new Date(invoiceData.created_at).toLocaleString("en-ZA")}</p>
                      {(invoiceData.tax_name != null) ?
                      <h4>{invoiceData.tax_name} (Reference: {invoiceData.tax_number})</h4> : ''
                    }
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between w-100 fw-bold">
                  <p>Buyer: {invoiceData.user.name}<br />
                    Vendor: {invoiceData.vendor.name}</p>
                </div>

                <div className="items">
                  <div className="order-items header">
                    <div className="item-name text-start">Item Name</div>
                    <div className="price text-center ">Price</div>
                    <div className="quantity text-center">Quantity</div>
                    <div className="item-total text-end">Total</div>
                  </div>
                  {invoiceData.order_items.data.map((item) => (
                    <div key={item.id} className="item py-1">
                      <div className="order-items">
                        <div className="item-name">
                          <span>
                            {item.variant.item.name} ({item.variant.name})
                          </span>
                          <br />
                          {item.item_options.data.map((option) =>
                          (
                            <span key={option.option.id}>
                              {option.option.name}
                              <br /></span>
                          )
                          )}
                        </div>
                        <div className="text-center price">
                          <span className="text-success">  {invoiceData.currency_symbol} {item.variant.price}</span><br />
                          {item.item_options.data.map((orderItem) =>

                            <span key={orderItem.option.id}>
                              <span className="text-success">  {invoiceData.currency_symbol} {orderItem.option.price}</span><br />
                            </span>

                          )}
                        </div>
                        <div className="quantity text-center">
                          <span >{item.quantity}</span>< br />
                          {item.item_options.data.map((orderItem) =>
                            <span key={orderItem.option.id}>
                              <span className="quantity">{item.quantity}</span><br />
                            </span>
                          )}
                        </div>
                        <span className="item-total text-success text-end">
                          $ {parseFloat(item.variant.price * item.quantity).toFixed(2)}<br />
                          {item.item_options.data.map((orderItem) =>
                            <span key={orderItem.option.id}>
                               {invoiceData.currency_symbol} {(orderItem.option.price * item.quantity).toFixed(2)}<br />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                {/* {showAdjustment ? <AdjustmentTotal invoiceData={invoiceData} /> : null}
                {showHST ? <HstTotal invoiceData={invoiceData} /> : <NoHstTotal invoiceData={invoiceData} />}
                {(invoiceData.tax_inclusive !== null && invoiceData.tax_inclusive === 'Includes' ) ? <IncludesFooter invoiceData={invoiceData} /> : null} */}
                {invoiceData.tax_inclusive !== null &&
                  invoiceData.tax_inclusive === "Includes" ? (
                    <div className="w-100 py-3 px-0">
                      <div className="row">
                        <ProductTotalIncludes
                          invoiceData={invoiceData}
                        ></ProductTotalIncludes>
                      </div>
                      <div className="row">
                        {showAdjustment ? (
                          <AdjustmentTotalIncludes invoiceData={invoiceData} />
                        ) : null}
                      </div>
                      <div className="row">
                        <OrderTotalIncludes
                          invoiceData={invoiceData}
                        ></OrderTotalIncludes>
                      </div>
                      <div className="row">
                        <IncludesFooter invoiceData={invoiceData} />
                      </div>
                    </div>
                  ) : null}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};


export default PrintInvoicePage;
