import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppHeader from "../common/AppHeader";
import "../PrintInvoicePage.css";
import api from "../services/api.service";
import Loader from "../common/Loader";
import { updateQueueItemState } from "../redux/features/queues/queuesSlice";

const OrdersPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { user, context } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = useState([]);
  const queuedState = queryParams.get("state");
  const showAdjustment = true;
  // useEffect(() => {
  //   // const style = document.createElement('style');
  //   // style.type = 'text/css';
  //   // style.innerHTML = `.offcanvas-backdrop { display: none !important; }`;
  //   // document.head.appendChild(style);
  //   // return () => {
  //   //   document.head.removeChild(style);
  //   // };
  // }, []);

  var AdjustmentTotal = (props) => {
    let invoiceData = props.invoiceData.cart;
    let hasTax =
      props.invoiceData.tax_name !== null && props.invoiceData.tax_name !== "";
    let includes =
      props.invoiceData.tax_inclusive === "Includes" ? "Includes " : "";
    if (!hasTax) {
      props.invoiceData.tax_name = "Tax";
    }
    return (
      <>
        <div>
          <p className="totaladjustment" style={{ padding: "0", margin: "0" }}>
            Service fee :{" "}
            <span className="text-success">
              {" "}
              {props.invoiceData.currency_symbol}{" "}
              {(+props.invoiceData.service_fee).toFixed(2)}{" "}
            </span>
          </p>
        </div>
        {includes ? (
          <div>
            <p
              className="totaladjustment"
              style={{ padding: "0", margin: "0" }}
            >
              {" "}
              {includes} {props.invoiceData.service_fee_tax_name} on Service fee
              :{" "}
              <span className="text-success">
                {" "}
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.service_fee_tax).toFixed(2)}{" "}
              </span>
            </p>
          </div>
        ) : (
          <div>
            <p
              className="totaladjustment"
              style={{ padding: "0", margin: "0" }}
            >
              {" "}
              {props.invoiceData.service_fee_tax_name}: (Reference:{" "}
              {props.invoiceData.service_fee_tax_number}) on Service fee :{" "}
              <span className="text-success">
                {" "}
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.service_fee_tax).toFixed(2)}{" "}
              </span>
            </p>
          </div>
        )}
      </>
    );
  };

  var AdjustmentTotalIncludes = (props) => {
    let invoiceData = props.invoiceData.cart;
    let hasTax =
      props.invoiceData.tax_name !== null && props.invoiceData.tax_name !== "";
    let includes =
      props.invoiceData.tax_inclusive === "Includes" ? "Includes " : "";
    if (!hasTax) {
      props.invoiceData.tax_name = "Tax";
    }
    return (
      <>
      <div>
          <div className="w-100 py-0 px-0 ">
            <div className="w-100 py-0 px-0 vatfooter">
              <div className="totals-row">
                <div>
                  KwikQ Service Fee : 
                </div>{" "}
                <div>
                <span className="text-success singleUnderline">
                  {" "}
                  {props.invoiceData.currency_symbol}{" "}
                  {(+props.invoiceData.service_fee).toFixed(2)}{" "}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  var IncludesFooter = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <div>
        <div className="w-100 py-3 px-0 vatfooterheader ">
          <p>VAT PAYMENTS</p>
          <div className="w-100 py-3 px-0 vatfooter">
            <div className="totals-row">
              <div>
                {invoiceData.tax_name} on product sales :{" "}
              </div>{" "}
              <div className="text-success">
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.tax_amount).toFixed(2)}
                <br />
              </div>
            </div>
            <div className="totals-row">
              <div>
                {invoiceData.tax_name} on service fee :{" "}
              </div>
              <div className="text-success">
                {props.invoiceData.currency_symbol}{" "}
                {(+props.invoiceData.service_fee_tax).toFixed(2)}
                <br />
              </div>
            </div>
            <div className="totals-row">
              <div>
                {invoiceData.tax_name} Total :{" "}
              </div>
              <div className="text-success">
                {props.invoiceData.currency_symbol}{" "}
                {(
                  +props.invoiceData.service_fee_tax +
                  +props.invoiceData.tax_amount
                ).toFixed(2)}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  var HstTotal = (props) => {
    let invoiceData = props.invoiceData;
    let includes =
      props.invoiceData.tax_inclusive === "Includes" ? "Includes " : "";
    return (
      <>
        <p className="fw-bold text-end">
          {includes}
          {invoiceData.tax_name} :{" "}
          {/* <span className="text-success">$ {parseFloat((invoiceData.total + 1) * .13).toFixed(2)}</span> */}
          {/* <span className="text-success">$ {parseFloat((+invoiceData.total + +invoiceData.cart.adjustment_total) * .13).toFixed(2)}</span> */}
          <span className="text-success">
            {props.invoiceData.currency_symbol}{" "}
            {parseFloat(+invoiceData.tax_amount).toFixed(2)}
          </span>
        </p>
      </>
    );
  };

  var OrderTotal = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <>
        <p className="fw-bold text-end pt-1">
          Order Total:{" "}
          {/* <span className="text-success">$ {parseFloat((invoiceData.total + 1) * 1.13).toFixed(2)}</span> */}
          {/* //Excludes*/}
          {/* <span className="text-success">$ {parseFloat((+invoiceData.total + +invoiceData.cart.adjustment_total) * 1.13).toFixed(2)}</span> */}
          {/* //Includes */}
          {invoiceData.tax_inclusive === "Includes" ? (
            <span className="text-success">
              {props.invoiceData.currency_symbol}{" "}
              {parseFloat(
                +invoiceData.total + +invoiceData.service_fee
              ).toFixed(2)}
            </span>
          ) : (
            <span className="text-success">
              {props.invoiceData.currency_symbol}{" "}
              {parseFloat(
                +invoiceData.total +
                  +invoiceData.service_fee +
                  +invoiceData.tax_amount +
                  +invoiceData.service_fee_tax
              ).toFixed(2)}
            </span>
          )}
        </p>
      </>
    );
  };

  var OrderTotalIncludes = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <>
        <div>
          <div className="w-100 py-0 px-0 vatfooterheader ">
            <div className="w-100 py-0 px-0 vatfooter">
              <div className="totals-row">
                <div>
                  Total :
                </div>{" "}
                <div>
                  {invoiceData.tax_inclusive === "Includes" ? (
                    <span className="text-success doubleUnderline">
                      {props.invoiceData.currency_symbol}{" "}
                      {parseFloat(
                        +invoiceData.total + +invoiceData.service_fee
                      ).toFixed(2)}
                    </span>
                  ) : (
                    <span className="text-success doubleUnderline">
                      {props.invoiceData.currency_symbol}{" "}
                      {parseFloat(
                        +invoiceData.total +
                          +invoiceData.service_fee +
                          +invoiceData.tax_amount +
                          +invoiceData.service_fee_tax
                      ).toFixed(2)}
                    </span>
                  )}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  var ProductTotalIncludes = (props) => {
    let invoiceData = props.invoiceData;
    return (
      <>
         <div>
          <div className="w-100 py-0 px-0 vatfooterheader ">
            <div className="w-100 py-0 px-0 vatfooter">
              <div className="totals-row">
                <div>
                 Product Total :
                </div>{" "}
                <div>
                    <span className="text-success">
                      {props.invoiceData.currency_symbol}{" "}
                      {parseFloat(
                        +invoiceData.total
                      ).toFixed(2)}
                    </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  var NoHstTotal = (props) => {
    let invoiceData = props.invoiceData;

    return (
      <>
        {/* <p className="fw-bold text-end pt-1">
        Service Fee:{" "}
        <span className="text-success">$ {parseFloat((+1)).toFixed(2)}</span>
      </p> */}
        <p className="fw-bold text-end pt-1">
          Order Total:{" "}
          <span className="text-success">
            {props.invoiceData.currency_symbol}{" "}
            {parseFloat(
              +invoiceData.total +
                +invoiceData.service_fee +
                +invoiceData.service_fee_tax
            ).toFixed(2)}
          </span>
        </p>
      </>
    );
  };

  useEffect(() => {
    api
      .get(`/orders/${queryParams.get("id")}`)
      .then((response) => {
        setInvoiceData(response.data.data);
        //console.log(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [user, navigate, context, dispatch, setIsLoading, queryParams]);

  const handleOkClick = () => {
    api
      .put(`/queues/${queryParams.get("queue_id")}`, {
        state: "collected",
      })
      .then((response) => {
        dispatch(updateQueueItemState(response.data.data));
        navigate(-1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Fragment>
      <AppHeader name={`Order: ${invoiceData.order_number}`}></AppHeader>
      <div id="appCapsule">
        {isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="invoice-wrapper p-md-5  p-sm-3  mt-5">
              <div className="invoice">
                <div className="w-100 d-flex align-items-center flex-column">
                  <div className="col-sm-12 col-md-4 text-start text-center">
                    <img
                      className="p-1 img-fluid w-50"
                      src={
                        queuedState === "done"
                          ? "/assets/img/notifications/done-late.png"
                          : queuedState === "new"
                          ? "/assets/img/notifications/new.png"
                          : queuedState === "busy"
                          ? "/assets/img/notifications/busy.png"
                          : "/assets/img/notifications/done.png"
                      }
                      alt="new"
                    />
                    {/* <div className="text-center">
                      <img
                        src="/assets/img/logo.png"
                        className="img-fluid"
                        width="100"
                        alt="logo"
                      />
                    </div> */}
                    <h2>Order: #{invoiceData.order_number}</h2>
                    <h3>{invoiceData.user.name}</h3>
                    <h4>{invoiceData.vendor.name}</h4>
                    {invoiceData.tax_name != null ? (
                      <h4>
                        {invoiceData.tax_name} (Reference:{" "}
                        {invoiceData.tax_number})
                      </h4>
                    ) : (
                      ""
                    )}
                    <h5 className="text-bold">
                      Ordered:{" "}
                      {new Date(invoiceData.created_at).toLocaleString("en-GB")}
                    </h5>
                  </div>

                  {context.type !== "Vendor" && queuedState === "done" && (
                    <button
                      className="btn btn-dark  btn-lg btn-block rounded-full mb-1 position "
                      data-bs-toggle="modal"
                      data-bs-target={`#queues-${invoiceData.id}`}
                    >
                      <span className="alt-main fs-2 bell-animate">
                        <ion-icon name="notifications"></ion-icon>
                      </span>{" "}
                      &nbsp; Mark as received
                    </button>
                  )}
                </div>

                <div
                  className="modal fade dialogbox"
                  id={`queues-${invoiceData.id}`}
                  data-bs-backdrop="static"
                  tabIndex="-1"
                  role="dialog"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {invoiceData.vendor.name}
                        </h5>
                      </div>
                      <div className="modal-body">
                        <h1>Order #{invoiceData.order_number}</h1>
                      </div>
                      <div className="modal-footer">
                        <div className="btn-inline">
                          <a
                            href="!#"
                            className="btn btn-text-secondary"
                            data-bs-dismiss="modal"
                          >
                            CLOSE
                          </a>
                          <a
                            href="!#"
                            className="btn btn-text-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              handleOkClick();
                            }}
                            data-bs-dismiss="modal"
                          >
                            OK
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fw-bold card-header p-0">Items</div>
                <div className="items">
                  <div className="order-items header">
                    <div className="item-name text-start">Item Name</div>
                    <div className="price text-center ">Price</div>
                    <div className="quantity text-center">Quantity</div>
                    <div className="item-total text-end">Total</div>
                  </div>
                  {invoiceData.order_items.data.map((item) => (
                    <div key={item.id} className="item py-1">
                      <div className="order-items">
                        <div className="item-name">
                          <span>
                            {item.variant.item.name} ({item.variant.name})
                          </span>
                          <br />
                          {item.item_options.data.map((option) => (
                            <span key={option.option.id}>
                              {option.option.name}
                              <br />
                            </span>
                          ))}
                        </div>
                        <div className="text-center price">
                          <span className="text-success">
                            {" "}
                            {invoiceData.currency_symbol}{" "}
                            {parseFloat(item.variant.price).toFixed(2)}
                          </span>
                          <br />
                          {item.item_options.data.map((orderItem) => (
                            <span key={orderItem.option.id}>
                              <span className="text-success">
                                {" "}
                                {invoiceData.currency_symbol}{" "}
                                {orderItem.option.price}
                              </span>
                              <br />
                            </span>
                          ))}
                        </div>
                        <div className="quantity text-center">
                          <span>{item.quantity}</span>
                          <br />
                          {item.item_options.data.map((orderItem) => (
                            <span key={orderItem.option.id}>
                              <span className="quantity">{item.quantity}</span>
                              <br />
                            </span>
                          ))}
                        </div>
                        <span className="item-total text-success text-end">
                          {invoiceData.currency_symbol}{" "}
                          {parseFloat(
                            item.variant.price * item.quantity
                          ).toFixed(2)}
                          <br />
                          {item.item_options.data.map((orderItem) => (
                            <span key={orderItem.option.id}>
                              {invoiceData.currency_symbol}{" "}
                              {(orderItem.option.price * item.quantity).toFixed(
                                2
                              )}
                              <br />
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                {/* {(invoiceData.tax_name !== null && invoiceData.tax_name !== '') ? <HstTotal invoiceData={invoiceData} /> : <></>} */}
                {/* {showAdjustment ? <AdjustmentTotal invoiceData={invoiceData} /> : null} */}
                {/* <OrderTotal invoiceData={invoiceData}></OrderTotal> */}
                <div className="row test">
                  {invoiceData.tax_inclusive !== null &&
                  invoiceData.tax_inclusive === "Includes" ? (
                    <div className="w-100 py-3 px-0">
                      <div className="row">
                        <ProductTotalIncludes
                          invoiceData={invoiceData}
                        ></ProductTotalIncludes>
                      </div>
                      <div className="row">
                        {showAdjustment ? (
                          <AdjustmentTotalIncludes invoiceData={invoiceData} />
                        ) : null}
                      </div>
                      <div className="row">
                        <OrderTotalIncludes
                          invoiceData={invoiceData}
                        ></OrderTotalIncludes>
                      </div>
                      <div className="row">
                        <IncludesFooter invoiceData={invoiceData} />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="w-100 py-3 px-0 buttonbar">
                    <div className=" text-muted w-100 text-end p-0">
                      <Link
                        to={`/order/print?id=${invoiceData.id}`}
                        className="btn btn-sm btn-primary"
                      >
                        Print
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default OrdersPage;
