import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate} from 'react-router-dom';

const StitchReturnPage = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search)

        axios
            .get(`/stitch/return`, {params: {id: searchParams.get('id'), status: searchParams.get('status')}})
            .then((response) => {
                navigate('/queues');
            })
            .catch((error) => {
                console.error(error);
            });
    }, [navigate]);

    return (
        <div>
        </div>
    );
}

export default StitchReturnPage;