import React from "react";

const NoRecordsFound = () => {
    return (
        <div className="section mt-3 mb-3" >
            <div className="card">
                <div className="card-body">
                    <p className="card-text">
                        No records found.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NoRecordsFound