import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import TimeAgo from "react-timeago";

const NotificationsBar = () => {
  const [isLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.queues.data) || [];
  const navigate = useNavigate();
  const sortedOrders = [...orders].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <div
      className="offcanvas offcanvas-start radius-bottom-right "
      tabIndex="-1"
      id="notifications_Panel"
    >
      <div className="offcanvas-body position-relative ">
        {user && (
          <div className="profileBox  text-center position-relative radius-top-right p-0">
            <div className="in text-center w-100">
              {/* <img
                className="img-fluid p-1"
                src="/assets/img/logo-color.png"
                alt="logo"
              /> */}

              <h2>Notifications</h2>
            </div>

            <a
              href="!#"
              className="close-sidebar-button main-color"
              data-bs-dismiss="offcanvas"
            >
              <ion-icon name="close"></ion-icon>
            </a>
          </div>
        )}

        {user && (
          <ul className="list-group list-group-flush pt-2">
            {isLoading && !sortedOrders ? (
              <Loader />
            ) : (
              <Fragment>
                {sortedOrders
                  .filter(
                    (queue) =>
                      (
                        queue.state !== "done"
                      )
                  )
                  .map((queue, index) => (
                    <li key={index}>
                      <div className="container">
                        <div className="row">
                          <div className="col-4 d-block text-center">
                            <img
                              src={
                                queue.state === "done" &&
                                new Date(queue.created_at) - Date.now() >
                                  20 * 60 * 1000
                                  ? "/assets/img/notifications/done-late.png"
                                  : queue.state === "new"
                                  ? "/assets/img/notifications/new.png"
                                  : queue.state === "busy"
                                  ? "/assets/img/notifications/busy.png"
                                  : "/assets/img/notifications/done.png"
                              }
                              alt="new"
                              width="95%"
                              height="auto"
                            />
                          </div>
                          <div className="col-6 d-flex align-items-center">
                            <div className="text-wrap special-lh">
                              <b>{queue.order.vendor.name}</b>
                              <br />

                              <small>
                                {queue.order.delivery_method === "collect"
                                  ? queue.state === "done"
                                    ? `Collect at ${queue.order.delivery_method_type||""} ${queue.order.vendor.address_1}`
                                    : `To be collected at ${queue.order.delivery_method_type ||""} ${queue.order.vendor.address_1}`
                                  : queue.state === "done"
                                  ? `delivering to  ${queue.order.delivery_method_type ||""} ${queue.order.delivery_method_location}`
                                  : `To be delivered to  ${queue.order.delivery_method_type ||""} ${queue.order.delivery_method_location}`}
                              </small>
                              <br />
                              <small>Order #{queue.order.order_number}</small>
                            </div>
                          </div>

                          <div className="col-2 d-flex align-content-center text-large mt-2">
                            <Link
                             to={`/order?id=${queue.order.id}&state=${queue.state}`}
                              className="alt-color"
                            
                            >
                              <ion-icon  data-bs-dismiss="offcanvas" name="chevron-forward"></ion-icon>
                            </Link>
                          </div>

                          <div className="w-100 text-end text-success ">
                            <small className="mb-5">
                              {" "}
                              <TimeAgo date={queue.created_at} units="second" />
                            </small>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </li>
                  ))}
                {sortedOrders.some(
                  (queue) =>( queue.state === "done"
                  )
                ) && (
                  <div className="container py-3">
                    <div className="row">
                      <div className="col-2">
                        <span className=" alt-color fs-1 bell-animate">
                          <ion-icon name="notifications"></ion-icon>
                        </span>{" "}
                      </div>
                      <div className="col-10">
                        <h5>REMEMBER TO MARK YOUR ORDER AS RECEIVED</h5>
                      </div>
                    </div>
                  </div>
                )}

                {sortedOrders
                  .filter(
                    (queue) => 
                      queue.state === "done" 
                  )
                  .map((queue, index) => (
                    <li key={index}>
                      <div className="container">
                        <div className="row">
                          <div className="col-4 d-block text-center">
                            <img
                              src={
                                queue.state === "done" &&
                                new Date(queue.created_at) - Date.now() <
                                  20 * 60 * 1000
                                  ? "/assets/img/notifications/done-late.png"
                                  : queue.state === "new"
                                  ? "/assets/img/notifications/new.png"
                                  : queue.state === "busy"
                                  ? "/assets/img/notifications/busy.png"
                                  : "/assets/img/notifications/done.png"
                              }
                              alt="new"
                              width="95%"
                              height="auto"
                            />
                          </div>
                          <div className="col-6 d-flex align-items-center">
                            <div className="text-wrap special-lh">
                              <b>{queue.order.vendor.name}</b>
                              <br />
                              <small>
                                {queue.order.delivery_method === "collect"
                                  ? queue.state === "done"
                                    ? `Collect at ${queue.order.delivery_method_type||""} ${queue.order.vendor.address_1}`
                                    : queue.order.vendor.address_1
                                  : queue.state === "done"
                                  ? `Delivering to ${queue.order.delivery_method_type||""} ${queue.order.delivery_method_location}`
                                  : `To be delivered to ${queue.order.delivery_method_type||""}  ${queue.order.delivery_method_location}`}
                              </small>
                              <br />
                              <small>Order #{queue.order.order_number}</small>
                            </div>
                          </div>

                          <div className="col-2 d-flex align-content-center text-large mt-2">
                            <Link
                             to={`/order?id=${queue.order.id}&state=${queue.state}&queue_id=${queue.id}`}
                              className="alt-color"
                           
                            >
                              <ion-icon  data-bs-dismiss="offcanvas" name="chevron-forward"></ion-icon>
                            </Link>
                          </div>

                          <div className="w-100 text-end text-success">
                            <small className="mb-n5">
                              {" "}
                              <TimeAgo date={queue.created_at} units="second" />
                            </small>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </li>
                  ))}
              </Fragment>
            )}
          </ul>
        )}
      </div>

      {!user && (
        <div className="sidebar-buttons p-1 d-flex justify-content-around radius-bottom-right text-center text-white">
          <a
            href="!#"
            className="btn rounded-full btn-primary w-45"
            onClick={(e) => navigate("/Login")}
            data-bs-dismiss="offcanvas"
          >
            Log In
          </a>
          |
          <a
            href="!#"
            className="btn rounded-full btn-primary w-45"
            onClick={(e) => navigate("/register")}
            data-bs-dismiss="offcanvas"
          >
            Register
          </a>
        </div>
      )}
    </div>
  );
};

export default NotificationsBar;
