import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  useNavigate } from 'react-router-dom'
import { setToast } from '../redux/features/app/globalSlice';
import Register from '../components/Register';

const RegisterPage = () => {
    const dispatch = useDispatch()  

    const { user, error } = useSelector(
        (state) => state.auth
    );

    const navigate = useNavigate()
    useEffect(() => {
        if (user) {
            navigate('/')
        }
    }, [navigate, user]);

    useEffect(() => {
        if (error) {
            var toast = {
                content: error,
                color: 'danger'
            };
            dispatch(setToast(toast));
        }
    }, [error, dispatch]);

    return (
        

            <div>
                <Register ></Register>
            </div>
       
    );
}

export default RegisterPage