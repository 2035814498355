import { useDispatch } from 'react-redux';
import { setToast } from '../redux/features/app/globalSlice';
import { useState } from 'react';
import api from "../services/api.service";


const VariantsOptionsUpdates = () => {
    const dispatch = useDispatch();
    const [isUpdating, setIsUpdating] = useState(false);

  const updateStatus = async (type, id, isActive) => {
    let endpoint = '';
    switch (type) {
        case 'item':
            endpoint = `/items/${id}`;
            break;
        case 'variant':
            endpoint = `/variants/${id}`;
            break;
        case 'option':  
            endpoint = `/options/${id}`;
            break;
        default:
            console.error('Invalid type');
            return;
    }

    setIsUpdating(true);
    try {
      await api.put(endpoint, { status: isActive ? 'active' : 'inactive' });
      dispatch(setToast({ content: 'Successfully updated status!', color: 'success' })); 
      return true;
    } catch (error) {
        dispatch(setToast({ content: 'Failed to update status.', color: 'danger' })); 
      console.error(error);
      return false;
    }finally {
        setIsUpdating(false);
      }
  };

  return {
    isUpdating, 
    updateStatus
  };
};

export default VariantsOptionsUpdates;