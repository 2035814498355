import axios from "axios";
import { logOut } from "../redux/features/auth/authSlice";
import  configureStore from "../redux/store";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create(
  {
    baseURL: API_URL
  }
);

api.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
)

api.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response?.status;
  
  if (statusCode === 401) {
    configureStore.dispatch(logOut());
    localStorage.setItem('redirect_url', window.location.href);
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
})

export default api;