import React, { useEffect, useState } from 'react'

const ManageNotifications = () => {
  const [ allowPushNotifications, setAllowPushNotifications ] = useState();

  const notify = (text = "Hi there!") => {
    // Respectfully ask for permission to send push notificaions
    if (!("Notification" in window)) {
        // Check if the browser supports notifications
        // alert("This browser does not support desktop notification");
    } else {
      if (Notification.permission === 'granted') {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        // const notification = new Notification(text);
        setAllowPushNotifications('granted');
      } else if (Notification.permission !== 'denied') {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          setAllowPushNotifications(permission);

          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            navigator.serviceWorker.getRegistration().then(function(reg) {
              if (reg) {
                reg.showNotification('Thank you for enabling notifications.');
              } else {
                console.log('no reg', reg)
              }
            });
          }
        });
      }
    }
  }

  useEffect(() => {
    if (!("Notification" in window)) {
        setAllowPushNotifications('denied');
    } else {
        setAllowPushNotifications(Notification.permission);
    }
  }, []);

  return (
    <div>
        { 
          allowPushNotifications !== 'granted' && allowPushNotifications !== 'denied' &&
            <div className='section m-1 shadow rounded p-2' >
              <div className="alert alert-light text-center" role="alert">
                <h3 className="alert-heading">Get Notified!</h3>
                <p>Enable notifications to get get notified of completed orders and promotions.</p>
        
                <button className='btn btn-dark rounded-full' onClick={() => notify("Thank you for enabling notifications.")}>Enable Notifications</button>
              </div>
            </div>
        }
    </div>
  )
}

export default ManageNotifications;