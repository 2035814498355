import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../components/Login';
import { useSelector } from 'react-redux';

const LoginPage = () => {
    const { user } = useSelector(
        (state) => state.auth
    );

    const navigate = useNavigate();
    useEffect(() => {
        if (user) {
            navigate('/')
        }
    }, [navigate, user])

    return (
        <Fragment >        
                <Login ></Login>
        </Fragment>
    );
}

export default LoginPage;