import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../redux/features/app/globalSlice";

const Toast = () => {
    const [ show, setShow ] = useState('show');
    const { toast } = useSelector((state) => state.global);

    const dispatch = useDispatch();

    useEffect(() => {
        setShow(true);
         
        const interval = setInterval(() => {
            setShow(null);
            dispatch(setToast(null));
        }, toast?.timeout ? toast.timeout : 3000);

        return () => {
            clearInterval(interval);
        }
    }, [toast, dispatch]);

    return (
        <>
            {
                toast && show && 
                <div className={`toast-box toast-${toast?.position ? toast.position : 'top'} bg-${toast?.color ? toast.color : 'dark'} ${show ? 'show' : null}`} style={{zIndex: 9999}}>
                    <div className="in">
                        <div className="text">
                            {toast?.content}
                        </div>
                    </div>
                    <button type="button" className="btn btn-sm btn-text-light close-button" onClick={() => setShow(false)}>OK</button>
                </div>
            }
        </>
    );
}

export default Toast;