import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/api.service";

export const fetchActiveQueuesAction = createAsyncThunk(
    'queues/active',
    async (context) => {
        try {
            var params = {};

            if (context.type === 'Vendor') {
                params.vendor_id = context.data.id
            } else {
                params.user_id = context.data.id
            }

            const { data } = await api.get('/queues/active', { params });

            localStorage.setItem('queues', JSON.stringify(data.data));

            return data.data;
        } catch (error) {

        }
    }
);